export const close = (index, pos, toast, setState) => { //and destroy
  let show_arr = [...toast[1]];
  show_arr[index] = false;
  setState(
    {
      showToast: show_arr,
    }, destroy, [pos,toast[0],setState]
  );
};

export const destroy = (position,toastData,setState) => {
  //nge rerender ketika masih ada yg di show apakah ga bakal masalah?
  let toast_data = [...toastData];
  toast_data.splice(position, 1, null);
  setState({
    toastData: toast_data,
  });
};

export const generateToast = (status, message,setState, toast) => {
  let idx = toast[1].length;
  let header = "";
  let header_class = "";
  switch (status) {
    case 0: //success
      header_class = "success";
      header = "Success!!";
      break;
    case 1: //error from input
      header_class = "warning";
      header = "Error!!";
      break;
    case 2: //error from server
      header_class = "danger";
      header = "Error!!";
      break;
    default:
      header_class = "";
      header = "Unknown";
      break;
  }
  let data = {
    class: header_class,
    header: header,
    msg: message,
    idx: idx,
    pos: toast[0].length,
  };
  setState({
    toastData: toast[0].concat(data),
    showToast: toast[1].concat(true),
  });
};
