import React, { Component } from "react";
import FormTransfer from "../Component/FormTransfer_Com";
import SideBar from "../Component/Sidebar_Com";
import { close, generateToast } from "../Logic/toast_handler";
import ToastComp from "../Component/Toast_Com";
import { storage } from "../Logic/handler";
import { Button, Modal } from "react-bootstrap";
import ServerError from "./ServerError";
import ModalLoading from "../Component/ModalLoading";

class Transfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: [],
      toastData: [],
      storageData: null,
      showModal: false,
      errorFetching: false,
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    let res = await storage(localStorage.getItem("token"), null, "get");
    if (res[0]) {
      if (res[0].status === 200) {
        this.setState({
          storageData: res[0].data,
          loading: false,
        });
      }
    } else {
      if (res[1].status === 401) {
        this.props.history.push({
          pathname: "/",
          state: {
            code: -1,
            msg: "Token tidak valid, mohon melakukan log-in kembali.",
          },
        });
      } else {
        this.setState({
          loading: false,
          errorFetching: true,
        });
      }
      return;
    }
  }

  setCurrState = (data, afterFunc, paramFunc) => {
    this.setState(
      {
        ...data,
      },
      () => {
        if (afterFunc) {
          afterFunc(...paramFunc);
        }
      }
    );
  };

  showModal = (isShow) => {
    this.setState({
      showModal: true,
    });
  };

  render() {
    return (
      <div className="home">
        <SideBar />
        <div className="main">
          {this.state.loading && <ModalLoading />}
          {!this.state.errorFetching ? (
            this.state.storageData && (
              <FormTransfer
                generateToast={generateToast}
                setParent={this.setCurrState}
                storageData={this.state.storageData}
                toast={[this.state.toastData, this.state.showToast]}
                showModal={this.showModal}
              />
            )
          ) : (
            <ServerError />
          )}
        </div>
        <div className="toast_container">
          {this.state.showToast.map((item, index) => {
            return (
              item && (
                <ToastComp
                  data={this.state.toastData[index]}
                  close={() =>
                    close(
                      this.state.toastData[index].idx,
                      this.state.toastData[index].pos,
                      [this.state.toastData, this.state.showToast],
                      this.setCurrState
                    )
                  }
                  isShow={this.state.showToast[index]}
                />
              )
            );
          })}
          <Modal
            show={this.state.showModal}
            onHide={() => this.props.history.push("/Storage")}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Sukses!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Data transfer telah berhasil disimpan</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.props.history.push("/Storage")}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Transfer;
