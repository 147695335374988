import {
  faDatabase,
  faCheckCircle,
  faGasPump,
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import {
  Accordion,
  Card,
  Button,
  useAccordionToggle,
  CardDeck,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import "../Style/Accordion_Style.css";
import { formatNumber } from "../Logic/form_handler";

function CustomToggle({ eventKey }) {
  const [toggle, setToggle] = useState(false);
  const onClick = useAccordionToggle(eventKey, () => setToggle(!toggle));

  return (
    <div className="box-icon">
      <FontAwesomeIcon
        icon={toggle ? faChevronDown : faChevronUp}
        as={Button}
        onClick={onClick}
        style={{cursor:"pointer"}}
      />
    </div>
  );
}
class GrafikData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //value: this.props.value,
      curr_storage: props.quantity,
      capacity: props.max_capacity,
      name: props.name,
      color: "",
      color_icon:""
    };
  }
  componentDidMount() {
    this.handleColor();
  }

  // Handle warna jika value dari storage :
  // value < 40% = hijau
  // value > 41% dan value < 75% = kuning
  // value > 76% = merah
  handleColor() {
    let red = "danger";
    let green = "success";
    let yellow = "warning";
    let green_icon = "#28a745"
    let red_icon = "#dc3545"
    let yellow_icon = "#ffc107"
    let value = this.state.curr_storage;
    let max = this.state.capacity;
    if ( value > 0 && value < max * 0.4) {
      this.setState({ color: green, color_icon:green_icon });
    } else if (value > max * 0.41 && value < max * 0.75) {
      this.setState({ color: yellow, color_icon:yellow_icon });
    } else {
      this.setState({ color: red, color_icon:red_icon });
    }
  }
  render() {
    return (
      <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
        <Card className="card-data storage-page">
          <Card.Header
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Card.Title>Nama Storage: {this.state.name}</Card.Title>
            <CustomToggle eventKey="0" />
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <CardDeck>
                <Card className="card-detail">
                  <Card.Body >
                    <Row>
                      <Col xs={12} md={8}>
                        <h5>Kapasitas Total</h5>
                        <p>{formatNumber(this.state.capacity)} Liter</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className="icon-grafik blue">
                          <FontAwesomeIcon icon={faDatabase}></FontAwesomeIcon>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="card-detail">
                  <Card.Body >
                    <Row>
                      <Col xs={12} md={8}>
                        <h5>Stok tersedia</h5>
                        <p>{formatNumber(this.state.curr_storage)} Liter</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className="icon-grafik red">
                          <FontAwesomeIcon icon={faGasPump}></FontAwesomeIcon>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="card-detail">
                  <Card.Body >
                    <Row>
                      <Col xs={12} md={8}>
                        <h5>Kapasitas Tersedia</h5>
                        <p>{formatNumber(this.state.capacity - this.state.curr_storage)} Liter</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className="icon-grafik" style={{backgroundColor: this.state.color_icon}}>
                          <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </CardDeck>
              <ProgressBar
                max={this.state.capacity}
                min={0}
                now={this.state.curr_storage}
                variant={this.state.color}
                label={formatNumber(this.state.curr_storage) + "L / " + formatNumber(this.state.capacity)+"L"}
              ></ProgressBar>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

export default GrafikData;
