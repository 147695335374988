import { Toast } from "react-bootstrap";
import { getTime } from "../Logic/form_handler";

function ToastComp (props) {
  return (
    <Toast
      animation
      className={props.data.class || "unknown"}
      show={props.isShow}
      onClose={() => props.close(props.data.idx, props.data.pos)}
      autohide
      delay={5000}
    >
      <Toast.Header>
        <strong className={"mr-auto "}>{props.data.header || "Unknown"}</strong>
        <small>{getTime()}</small>
      </Toast.Header>
      <Toast.Body>
        {props.data.msg ||
          "Some undocummented error occured. Please contact the administrator to look at this problem"}
      </Toast.Body>
    </Toast>
  );
};

export default ToastComp;
