import React, { Component } from "react";
import "../Style/Default_Style.css";
// import {dataSet, detailDataSet, log, dataVendor} from "../Data/Dummy_Data";


// const $ = require("jquery");
// $.DataTable = require("datatables.net");

// const columnsStorage = [
//   { title: "Nama Storage", data: "name" },
//   { title: "Maksimal Storage", data: "maks_storage" },
//   { title: "Isi Storage Sekarang", data: "curr_storage" },
//   {
//     title: "Detail",
//     data: "detail",
//     fnCreatedCell: function (nTd) {
//       $(nTd).html("<Button><a href='/DetailStorage'>Lihat</a></Button>");
//     },
//   },
// ];
// const columnsDetailStorage = [
//   { title: "Date", data: "date" },
//   { title: "Kategori", data: "category" },
//   { title: "Kapasitas", data: "capacity" },
//   { title: "Sumber Storage", data: "origin"},
//   { title: "Tujuan Storage", data: "destination"},
//   { title: "Perusahaan", data:"company"}
// ];
// const columnsLog = [
//   { title: "Hari", data: "day" },
//   { title: "Tanggal", data: "created_at" },
//   { title: "Waktu", data: "created_at" },
//   { title: "Keterangan", data: "information" },
// ];
// const columnsCustomer = [
//   { title: "Nama Perusahaan", data: "name" },
//   { title: "Alamat Perusahaan", data: "address" }
// ];

// export const generateHeader = (table) => {
//   let colum = {};
//   switch (table) {
//     case "Storage":
//       colum = columnsStorage;
//       break;
//     case "Detail Storage":
//       colum = columnsDetailStorage;
//       break;
//     case "Log":
//       colum = columnsLog;
//       break;
//     case "Customer":
//       colum = columnsCustomer;
//       break;
//     default:
//       break;
//   }
//   return colum;
// };

// export const generateDataDummy = (table) => {
//   let colum = [];
//   switch (table) {
//     case "Storage":
//       colum = dataSet;
//       break;
//     case "Detail Storage":
//       colum = detailDataSet;
//       break;
//     case "Log":
//       colum = log;
//       break;
//     case "Customer" :
//       colum = dataCustomer;
//       break;
//     default:
//       break;
//   }
//   return colum;
// };

class Table extends Component {
  // componentDidMount() {
  //   this.$el = $(this.el);
  //   this.$el.DataTable({
  //     scrollY: 337,
  //     data: generateDataDummy(this.props.type),
  //     columns: generateHeader(this.props.type),
  //   });
  // }

  render() {
    return (
      <table
        id="data_table"
        className="stripe"
        width="100%"
        //ref={(el) => (this.el = el)}
        ref={this.props.childRef}
      ></table>
    );
  }
}
export default Table;
