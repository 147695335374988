import React, { useState } from "react";
import { Form, Button, Spinner, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "../Style/Form_Style.css";
import "../Style/Default_Style.css";
import { company } from "../Logic/handler";
import { useLocation } from "react-router-dom";

function CustomerCom(props) {
  const { register, errors, handleSubmit, setValue, getValues } = useForm({
    mode:"all",
    //validateCriteriaMod: "all", // versi useForm skrg sudah di mode:"all"
    defaultValues:{
      name : props.location.state ? props.location.state.name : "",
      address : props.location.state ? props.location.state.address : "",
      //category: props.location.state ? props.location.state.category : "customer",
    }
  });
  const location = useLocation();
  const [state, setState] = useState({
    isSubmit : false,
    edit: props.location.state ? true : false,
    loading: false,
  })

  const onSubmit = async (data) => {
    setState({...state, isSubmit:true});
    data._id = props.location.state ? props.location.state._id : null;
    let success = false;
    let res = await company(localStorage.getItem("token"), data, state.edit ? "put" : "post");
    if (res[0]) {
      if (res[0].status === 200) {
        success = true;
        props.showModal(data.category);
      }
    } else {
      if (res[1].status === 401) {
        this.props.history.push({
          pathname: "/",
          state: {
            code: -1,
            msg: "Token tidak valid, mohon melakukan log-in kembali.",
          },
        });
        return;
      }
      if (Array.isArray(res[1].data)) {
        if (res[1].data) {
          for (const key in res[1].data) {
            props.generateToast(
              1,
              "Status " + res[1].status + ": " + res[1].data[key].msg,
              props.setParent,
              props.toast
            );
          }
        }
      } else {
        props.generateToast(
          1,
          "Status " + res[1].status + ": " + res[1].message,
          props.setParent,
          props.toast
        );
      }
    }
    setState({...state, isSubmit:false});
    if(success) props.showModal(state.edit ? "edit" : "input", res[0].data)
  };

  return (
    <div className="container-form form-inside-page">
      {state.edit && (
        <h4>
          Sedang mengedit {location.pathname === "/Customer/FormVendor" ? (
            "Customer"
          ) : (
            location.pathname === "/Supplier/FormVendor" && (
              "Supplier"
            )
          )}
          <b>
            {" "}
            Perusahaan {props.location.state.name}
          </b>
        </h4>
      )}
      <Card className="card-form">
        <Card.Header>
          {location.pathname === "/Supplier/FormVendor" ? (
            <h5>Form Supplier</h5>
          ) : (
            location.pathname === "/Customer/FormVendor" && (
              <h5>Form Customer</h5>
            )
          )}
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)} className="form">
          <Card.Body>
            <Form.Group controlId="formBasicCompany">
              <Form.Label>Nama Perusahaan :</Form.Label>
              <Form.Control
                type="text"
                ref={register({
                  required: "Input tidak boleh kosong.",
                })}
                name="name"
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Group controlId="formBasicAlamat">
              <Form.Label> Alamat Perusahaan :</Form.Label>
              <Form.Control
                name="address"
                type="text"
                ref={register({
                  required: "Input tidak boleh kosong.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="address"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Group controlId="formBasicCategory">
              <Form.Label>Kategori :</Form.Label>
              {location.pathname === "/Supplier/FormVendor" ? (
                <Form.Control name="category" as="select" ref={register()}>
                  <option value="supplier">Supplier</option>
                  <option value="customer">Customer</option>
                </Form.Control>
              ) : (
                location.pathname === "/Customer/FormVendor" && (
                  <Form.Control name="category" as="select" ref={register()}>
                    <option value="customer">Customer</option>
                    <option value="supplier">Supplier</option>
                  </Form.Control>
                )
              )}
            </Form.Group>
          </Card.Body>
          <Card.Footer>
            <div className="btn-container">
              <Button variant="primary" type="submit" disabled={state.isSubmit}>
                {state.isSubmit ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Card.Footer>
        </Form>
      </Card>
    </div>
  );
}

export default CustomerCom;
