import {
  Button,
  Form,
  Image,
  Spinner,
  Toast,
  InputGroup,
} from "react-bootstrap";
import React, { Component } from "react";
// import ModalLoading from "../components/ModalLoading_Com"
import "../Style/LoginForm_Style.css";
import "../Style/Form_Style.css";
import "../Style/Colour_Style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { auth } from "../Logic/handler";
// import { data } from "jquery";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hidden: false,
      show: props.location.state ? true : false,
      toastMsg:
        props.location.state && props.location.state.code === -1
          ? props.location.state.msg
          : "",
    };
    localStorage.clear();
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
    this.newToken = "";
    this.password = "";
    this.username = "";
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  toggleShowPassword() {
    this.setState({ hidden: !this.state.hidden });
  }
  toggleShow = () => {
    this.setState({
      show: false,
    });
  };
  toggle() {
    this.setState({ addClass: !this.state.addClass });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let show =
      this.password === "" || this.username === "" || this.password.length < 8;
    let toastMsg =
      this.password === "" || this.username === ""
        ? "Username / Password tidak boleh kosong"
        : this.password.length < 8
        ? "Password tidak boleh kurang dari 8 digit"
        : "";
    if (show) {
      this.setState({
        show: show,
        toastMsg: toastMsg,
      });
      return;
    }
    this.setState({ loading: true });
    let resp = await auth(this.username, this.password);
    if (resp[0]) {
      if (resp[0].status === 200) {
        const promised = new Promise((resolve, reject) => {
          if (resp[0].data.isAdminMaster || !resp[0].data.isAdminMaster) {
            const dataKey = ["isAdminMaster", "token"];
            dataKey.forEach((key) =>
              localStorage.setItem(key, resp[0].data[key])
            );
            resolve(true);
          } else {
            reject(true);
          }
        }).then(
          (resolve) => this.props.history.replace("/Storage"),
          (reject) =>
            this.setState({
              show: true,
              toastMsg: "Kredensial tidak valid",
            })
        );
      } else {
        // something?
      }
    } else {
      show = true;
      if (resp[1].status === 400) toastMsg = "Username tidak terdaftar";
      else if (resp[1].status === 401)
        toastMsg = "Username / Password Anda Salah";
      else if (resp[1].status === 408)
        toastMsg = "Autentikasi berjalan terlalu lama, silahkan coba lagi";
      else
        toastMsg =
          "Terjadi kesalahan pada server, silahkan coba lagi atau hubungi admin";
    }
    this.setState({
      loading: false,
      show: show,
      toastMsg: toastMsg,
    });
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.code) {
      this.props.history.replace(this.props.history.location.pathname);
    }
  }

  render() {
    return (
      <>
        <div className="box-LoginForm">
          <div className="container-LoginForm">
            <div className="logo-container">
              <h2 className="judul-login">Storage</h2>
              <Image
                className="logo-mkm"
                src="assets/logo/mkm-transparent.png"
              />
            </div>
            <Form className="form-LoginForm" onSubmit={this.handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label style={{ fontSize: 16 }}>USERNAME :</Form.Label>
                <Form.Control
                  name="username"
                  type="text"
                  onChange={(e) => (this.username = e.target.value)}
                  disabled={this.state.loading}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label style={{ fontSize: 16 }}>PASSWORD :</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="input-Password"
                    name="password"
                    type={this.state.hidden ? "text" : "password"}
                    onChange={(e) => (this.password = e.target.value)}
                    disabled={this.state.loading}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                    {!this.state.hidden ? (
                      <FontAwesomeIcon
                        onClick={() => {
                          this.toggle.bind(this);
                          this.toggleShowPassword();
                        }}
                        icon={faEyeSlash}
                      />
                    ) : (
                      <FontAwesomeIcon
                        onClick={() => {
                          this.toggle.bind(this);
                          this.toggleShowPassword();
                        }}
                        icon={faEye}
                      />
                    )
                    }
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              <div id="btn-container-login">
                <Button variant="primary" id="btn-loginForm" type="submit">
                  {this.state.loading ? (
                    <Spinner animation="border" variant="secondary" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Form>
          </div>
          {/*Toast*/}
          <div className="container-Toast">
            <Toast
              className="box-Toast"
              show={this.state.show}
              onClose={() => this.setState({ show: false })}
              delay={4000}
              autohide
            >
              <Toast.Body className="Toast-Body" style={{ color: "#f47121" }}>
                {this.state.toastMsg}
              </Toast.Body>
            </Toast>
          </div>
        </div>
      </>
    );
  }
}

export default LoginForm;
