import React, { Component } from "react";
import Vendor from "../Component/FormVendor_Com";
import SideBar from "../Component/Sidebar_Com";
import { close, generateToast } from "../Logic/toast_handler";
import ToastComp from "../Component/Toast_Com";
// import { storage } from "../Logic/handler";
import { Button, Modal } from "react-bootstrap";

class FormVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: [],
      toastData: [],
      showModal: false,
      closePath: "/Storage", //default
    };
  }

  setCurrState = (data, afterFunc, paramFunc) => {
    console.log(data);
    this.setState(
      {
        ...data,
      },
      () => {
        if (afterFunc) {
          afterFunc(...paramFunc);
        }
      }
    );
  };

  showModal = (path, type) => {
    console.log(path)
    let url;
    if(path === "customer"){
      url = "Customer"
    }else{
      url = "Supplier"
    }
    //let url = path === "customer" ? "Customer" : "Supplier"
    this.setState({
      showModal: true,
      closePath: "/Supplier",
    });
  };

  render() {
    return (
      <div className="home">
        <SideBar />
        <div className="main">
          <Vendor
            generateToast={generateToast}
            setParent={this.setCurrState}
            toast={[this.state.toastData, this.state.showToast]}
            showModal={this.showModal}
            category={"supplier"}
            {...this.props}
          />
        </div>
        <div className="toast_container">
          {this.state.showToast.map((item, index) => {
            return (
              item && (
                <ToastComp
                  data={this.state.toastData[index]}
                  close={() =>
                    close(
                      this.state.toastData[index].idx,
                      this.state.toastData[index].pos,
                      [this.state.toastData, this.state.showToast],
                      this.setCurrState
                    )
                  }
                  isShow={this.state.showToast[index]}
                />
              )
            );
          })}
          <Modal
            show={this.state.showModal}
            onHide={() => this.props.history.push(this.state.closePath)}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Sukses!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Data <b>{this.state.close}</b> telah berhasil disimpan</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.props.history.push(this.state.closePath)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default FormVendor;
