import Axios from "axios";
import { sanitizeSeperator } from "../Logic/form_handler";

const useData = ["post", "put"];
const timeout = 30000;
const timeoutErrorMessage =
  "Request Time Out: proses telah melebihi 30 detik. Silahkan coba lagi.";

const handleError = error => {
  let errorResponse = null;
  if (error.code === "ECONNABORTED") {
    errorResponse = {
      status: 408,
      error: [{ msg: error.message }],
      message: "Request Time Out"
    };
  } else if (error.response) {
    errorResponse = error.response.data;
    if (errorResponse.access_token)
      localStorage.setItem("token", errorResponse.access_token);
  } else {
    //bisa karena gada error response, etc
    errorResponse = {
      status: 501,
      error: [{ msg: "Server Implementation error." }]
    };
  }
  return errorResponse;
};

const hitAPI = async param => {
  var response,
    error = null;
  await Axios({
    ...param
  })
    .then(r => {
      response = r.data;
      if (response.access_token)
        localStorage.setItem("token", response.access_token);
    })
    .catch(e => {
      error = handleError(e);
    });
  return [response, error];
};

export const auth = async (username, password) => {
  let param = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    baseURL: process.env.REACT_APP_API_URL,
    url: process.env.REACT_APP_API_LOGIN,
    data: {
      username: username,
      password: password
    },
    timeout: timeout,
    timeoutErrorMessage: timeoutErrorMessage
  };
  return await hitAPI(param);
};

export const storage = async (token, value, method, other) => {
  var url = null;
  switch (method) {
    case "put":
      url = process.env.REACT_APP_API_STORAGE + "update/" + value._id;
      break;
    case "delete":
      url = process.env.REACT_APP_API_STORAGE + "/delete/" + value._id;
      break;
    default:
      //get or post
      url = process.env.REACT_APP_API_STORAGE;
      if (other) url += other;
      break;
  }
  console.log(value);
  let param = {};
  param.method = method;
  param.baseURL = process.env.REACT_APP_API_URL;
  param.url = url;
  param.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };
  param.timeout = timeout;
  param.timeoutErrorMessage = timeoutErrorMessage;
  if (value) {
    // console.log(
    //   "kuantitas: " + value.quantity + ", bb: " + value.fuel_consumption
    // );
    value.fuel_consumption = 0;
    value.quantity = sanitizeSeperator(value.quantity);
    value.max_capacity = sanitizeSeperator(value.max_capacity);
  }
  console.log(value);
  if (useData.includes(method)) param.data = value;

  let result = await hitAPI(param);
  if (method === "get") {
    sort("storage", result);
  }
  console.log(result);
  return result;
};

export const transaction = async (token, value, method) => {
  var url = null;
  switch (method) {
    case "get":
      break;
    case "customer":
      url = process.env.REACT_APP_API_TRANSACTION + "filter/customer/" + value._id
      break;
    default:
      //post
      url = process.env.REACT_APP_API_TRANSACTION;
      break;
  }

  let param = {};
  param.method = method;
  param.baseURL = process.env.REACT_APP_API_URL;
  param.url = url;
  param.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };
  param.timeout = timeout;
  param.timeoutErrorMessage = timeoutErrorMessage;
  if (value) {
    value.fuel_consumption = 0;
    console.log(
      "kuantitas: " + value.quantity + ", bb: " + value.fuel_consumption
    );
    value.quantity = sanitizeSeperator(value.quantity.toString());
    if (
      value.category === "sell" ||
      value.buy === "sell" ||
      value.buy === "return"
    ) {
      value.fuel_consumption = sanitizeSeperator(
        value.fuel_consumption.toString()
      );
    }
  }
  if (useData.includes(method)) param.data = value;

  return await hitAPI(param);
};

export const transactionbycustomer = async (token, value, method) => {
  var url = null;
  url = process.env.REACT_APP_API_TRANSACTION + "filter/customer/" + value

  let param = {};
  param.method = method;
  param.baseURL = process.env.REACT_APP_API_URL;
  param.url = url;
  param.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };
  param.timeout = timeout;
  param.timeoutErrorMessage = timeoutErrorMessage;
  return await hitAPI(param);
};

export const company = async (token, value, method, category) => {
  var url = null;
  var data = {};
  switch (method) {
    case "put":
      url = process.env.REACT_APP_API_COMPANY + "update/" + value._id;
      break;
    case "delete":
      url = process.env.REACT_APP_API_COMPANY + "/delete/" + value._id;
      break;
    case "get":
      url = process.env.REACT_APP_API_COMPANY + "category/" + category;
      break;
    default:
      url = process.env.REACT_APP_API_COMPANY;
      break;
  }
  let param = {};
  param.method = method;
  param.baseURL = process.env.REACT_APP_API_URL;
  param.url = url;
  param.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  param.timeout = timeout;
  param.timeoutErrorMessage = timeoutErrorMessage;
  if (useData.includes(method)) param.data = value;

  let result = await hitAPI(param);
  if (method === "get") {
    sort("company", result);
  }
  return result;
};

const sort = (type, data) => {
  if (!data[0].data) {
    return data;
  }
  switch (type) {
    case "company":
      data[0].data.sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return data;
      break;
    case "storage":
      data[0].data.sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return data;
      break;

    default:
      break;
  }
};

export const changePassword = async (token, value) => {
  let param = {
    method: "put",
    url: process.env.REACT_APP_API_CHANGE_PASS,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    timeout: timeout,
    timeoutErrorMessage: timeoutErrorMessage,
    data: {
      old_password: value.oldpassword,
      new_password: value.newpassword
    }
  };

  return await hitAPI(param);
};

export const log = async token => {
  let param = {};
  param.method = "get";
  param.baseURL = process.env.REACT_APP_API_URL;
  param.url = process.env.REACT_APP_API_LOG;
  param.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  param.timeout = timeout;
  param.timeoutErrorMessage = timeoutErrorMessage;

  return await hitAPI(param);
};

export const getTime = data => {
  if (!data) {
    let currDT = new Date();
    let hour =
      currDT.getHours() < 10 ? "0" + currDT.getHours() : currDT.getHours();
    let minute =
      currDT.getMinutes() < 10
        ? "0" + currDT.getMinutes()
        : currDT.getMinutes();
    return hour + ":" + minute;
  } else {
    let currDT = new Date(data);
    let year = currDT.getFullYear();
    let month = addZero(currDT.getMonth()) + currDT.getMonth();
    let day = addZero(currDT.getDate()) + currDT.getDate();
    return year + "-" + month + "-" + day;
  }
};

export const formatDate = data => {
  console.log("data: "+ JSON.stringify(data))
  var startDate = new Date(data);
  startDate =
    (startDate.getDate() < 10 ? "0" : "") +
    startDate.getDate() +
    "/" +
    (startDate.getMonth() + 1 < 10 ? "0" : "") +
    (startDate.getMonth() + 1) +
    "/" +
    startDate.getFullYear();
  return startDate;
};

const addZero = obj => (obj < 10 ? "0" : "");

export const getCompleteTime = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = addZero(date.getMonth()) + date.getMonth();
  let day = addZero(date.getDate()) + date.getDate();
  let hour = addZero(date.getHours()) + date.getHours();
  let minutes = addZero(date.getMinutes()) + date.getMinutes();
  let second = addZero(date.getSeconds()) + date.getSeconds();
  let timezone = date.getTimezoneOffset();
  let offset = timezone < 0 ? "+" : "-";
  timezone = parseInt(timezone / 60);
  timezone = timezone < 0 ? timezone * -1 : timezone;
  timezone = addZero(timezone) + timezone;

  return (
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hour +
    ":" +
    minutes +
    ":" +
    second +
    offset +
    timezone +
    ":00"
  );
};
