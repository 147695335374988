import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginForm from "../src/Page/Login_Page";
import Pembelian from "../src/Page/FormPembelian_Page";
import Penjualan from "../src/Page/FormPenjualan_Page";
import Transfer from "../src/Page/FormTransfer_Page";
import Adjustment from "./Page/Adjustment_Page";
import GantiPassword from "../src/Page/GantiPassword_Page";
import Storage from "../src/Page/TableStorage_Page";
import DetailStorage from "../src/Page/DetailStorage_Page";
import TransactionCustomer from "../src/Page/TableTransactionCustomer_Page";
import Log from "../src/Page/Log_Page";
import Customer from "./Page/TableCustomer_Page";
import Supplier from "./Page/TableSupplier_Page";
import "bootstrap/dist/css/bootstrap.min.css";
import PrivateRoute from "./PrivateRoute";
import BadRequest from "./Page/BadRequest";
import FormVendorCustomer from "../src/Page/FormCustomer_Page";
import FormVendorSupplier from "../src/Page/FormSupplier_Page";
import FormStorage from "../src/Page/FormStorage_Page";
import Pengembalian from "./Page/FormReturn_Page";


const $ = require("jquery");
$.Datatable = require("datatables.net");

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginForm} />
        <PrivateRoute exact path="/Storage" dt={$} component={Storage} />
        <PrivateRoute exact path="/FormStorage" component={FormStorage} />
        <PrivateRoute exact path="/Customer" dt={$} component={Customer} />
        <PrivateRoute exact path="/Supplier" dt={$} component={Supplier} />
        <PrivateRoute exact path="/Pembelian" component={Pembelian} />
        <PrivateRoute exact path="/Penjualan" component={Penjualan} />
        <PrivateRoute exact path="/Transfer" component={Transfer} />
        <PrivateRoute exact path="/Adjustment" component={Adjustment} />
        <PrivateRoute exact path="/GantiPassword" component={GantiPassword} />
        <PrivateRoute exact path="/DetailStorage" dt={$} component={DetailStorage} />
        <PrivateRoute exact path="/TransactionCustomer" dt={$} component={TransactionCustomer} />
        <PrivateRoute exact path="/Customer/FormVendor" component={FormVendorCustomer} />
        <PrivateRoute exact path="/Supplier/FormVendor" component={FormVendorSupplier} />
        <PrivateRoute exact path="/Log" dt={$} component={Log} />
        <PrivateRoute exact path="/Return" component={Pengembalian} />
        <Route path="*" component={BadRequest}/>
      </Switch>
    </Router>
  );
}

export default App;