export function handleTextInput(name, event) {
  let res = event.target.value;
  if (res === " ") return "";
  let regex_text = /^[a-zA-Z\s]+$/;
  let regex_email = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
  let regex_lc = /(\b[a-z](?!\s))/g;
  let status = false;

  switch (name) {
    default:
      res = res.replace(regex_lc, function(x) {
        return x.toUpperCase();
      });
      res = res.replace(/\s\s+/g, " ");
      status = true;
      break;
  }
  return res;
}

export function handleNumberInput(event) {
  let res = event.target.value;
  let regex_number = /[^\d]/g;

  res = res.replace(regex_number, "");
  let test = res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  console.log("a: " + test + ", b: " + sanitizeSeperator(test) )
  return res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatNumber(num) {
if(!num){
  return num
}

  let res = num.toString();
  let regex_number = /[^\d]/g;

  res = res.replace(regex_number, "");
  return res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function sanitizeSeperator(number) {
  var num = number.toString().split('.').join("");
  console.log(number)

  return parseInt(num);
}

export function condensingWhitespace(data) {
  const condenseWhitespace = require("condense-whitespace");

  const keys = Object.keys(data);
  for (const key of keys) {
    if (typeof data[key] === "string") {
      data[key] = condenseWhitespace(data[key]);
    }
  }

  return data;
}

export const getTime = () => {
  let currDT = new Date();
  let hour =
    currDT.getHours() < 10 ? "0" + currDT.getHours() : currDT.getHours();
  let minute =
    currDT.getMinutes() < 10 ? "0" + currDT.getMinutes() : currDT.getMinutes();
  return hour + ":" + minute;
};
