import React, { Component } from "react";
import FormPembelian from "../Component/FormPembelian_Com";
import SideBar from "../Component/Sidebar_Com";
import "../Style/Default_Style.css";
import "../Style/Form_Style.css";
import { close, generateToast } from "../Logic/toast_handler";
import ToastComp from "../Component/Toast_Com";
import { company, storage } from "../Logic/handler";
import { Button, Modal } from "react-bootstrap";
import ServerError from "./ServerError";
import ModalLoading from "../Component/ModalLoading";

class Pembelian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: [],
      toastData: [],
      supplierData: null,
      storageData: null,
      showModal: false,
      errorFetching: false,
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    })
    let fetchData = [];
    fetchData.push(
      await company(localStorage.getItem("token"), null, "get", "supplier")
    );
    fetchData.push(await storage(localStorage.getItem("token"), null, "get"));
    if (
      fetchData[0][0] &&
      fetchData[1][0] &&
      fetchData[0][0].status === 200 &&
      fetchData[1][0].status === 200
    ) {
      this.setState({
        supplierData: fetchData[0][0].data,
        storageData: fetchData[1][0].data,
        loading: false,
      });
    } else {
      if (fetchData[0][0].status === 401 || fetchData[1][0].status === 401) {
        this.props.history.push({
          pathname: "/",
          state: {
            code: -1,
            msg: "Token tidak valid, mohon melakukan log-in kembali.",
          },
        });
      } else {
        this.setState({
          errorFetching: true,
          loading: false,
        });
      }
      return;
    }
  }

  setCurrState = (data, afterFunc, paramFunc) => {
    this.setState(
      {
        ...data,
      },
      () => {
        if (afterFunc) {
          afterFunc(...paramFunc);
        }
      }
    );
  };

  showModal = (isShow) => {
    this.setState({
      showModal: true,
    });
  };

  render() {
    return (
      <div className="home">
        <SideBar />
        <div className="main">
          {this.state.loading && <ModalLoading/>}
          {!this.state.errorFetching ? (
            this.state.storageData &&
            this.state.supplierData && (
              <FormPembelian
                generateToast={generateToast}
                setParent={this.setCurrState}
                data={[this.state.supplierData, this.state.storageData]}
                toast={[this.state.toastData, this.state.showToast]}
                showModal={this.showModal}
              />
            )
          ) : (
            <ServerError />
          )}
        </div>
        <div className="toast_container">
          {this.state.showToast.map((item, index) => {
            return (
              item && (
                <ToastComp
                  data={this.state.toastData[index]}
                  close={() =>
                    close(
                      this.state.toastData[index].idx,
                      this.state.toastData[index].pos,
                      [this.state.toastData, this.state.showToast],
                      this.setCurrState
                    )
                  }
                  isShow={this.state.showToast[index]}
                />
              )
            );
          })}
          <Modal
            show={this.state.showModal}
            onHide={() => this.props.history.push("/Storage")}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Sukses!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Data pembelian telah berhasil disimpan</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.props.history.push("/Storage")}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Pembelian;
