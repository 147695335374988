import React, { Component } from "react";
import { Row } from "react-bootstrap";
import SideBar from "../Component/Sidebar_Com";
import Table from "../Component/Table_Com";
import "../Style/Table_Style.css";
import { log } from "../Logic/handler";
import Timeout from "./TimeOut";
import ServerError from "./ServerError";

class Log extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.$ = props.dt;
    this.state = {
      error: false,
    };
  }

  componentDidMount() {
    this.$el = this.$(this.tableRef.current);
    var componentInstance = this;
    let dataTable = this.$el.DataTable({
      serverSide: false,
      scrollY: 337,
      scrollCollapse: true,
      processing: true,
      ajax: async (data, callback, settings) => {
        let resp = await log(localStorage.getItem("token"));
        let dataInject = [];
        if (resp[0]) {
          if (resp[0].status === 200) dataInject = resp[0].data;
          callback({
            data: dataInject,
          });
        } else {
          if(resp[1].status === 401){
            this.props.history.push({pathname: "/", state: {code: -1, msg: "Token tidak valid, mohon melakukan log-in kembali."}})
            return;
          }
          this.setState({
            error: resp[1].status,
          });
          return;
        }
      },
      columns: [
        {
          data: "day",
          title: "Hari",
        },
        {
          data: "created_at",
          title: "Tanggal",
          render: (data, type, row, meta) => {
            return data.substring(0, 10);
          },
          type: "date",
        },
        {
          data: "time",
          title: "Waktu",
        },
        {
          data: "information",
          title: "Keterangan",
          orderable: false,
        },
      ],
      drawCallback: function () {
        const promise = new Promise((resolve, reject) => {
          //handle if user switch page too fast
          try {
            var api = this.api();
            var rowCount = api.rows({ page: "current" }).count();
            let td = "<td>&nbsp;</td>";
            for (let i = 0; i < api.columns()[0].length - 1; i++) {
              td = td + "<td></td>";
            }
            let rowType = rowCount % 2 === 1 ? "even" : "odd";
            for (
              var i = 0;
              i < api.page.len() - (rowCount === 0 ? 1 : rowCount);
              i++
            ) {
              componentInstance
                .$("#data_table tbody")
                .append(
                  componentInstance.$(
                    "<tr class='" + rowType + "' role='row'>" + td + "</tr>"
                  )
                );
              rowType = rowType === "odd" ? "even" : "odd";
            }
            resolve(true);
          } catch (err) {
            reject(err);
          }
        });
        promise.then(
          //no need to do anything
          (resolve) => {},
          (reject) => {}
        );
      },
      order: [
        [1, "desc"],
        [2, "desc"],
      ],
    });
    componentInstance.$("#data_table_info").appendTo(".info_paging_container");
    componentInstance.$("#data_table_paginate").appendTo(".info_paging_container");
    dataTable.draw();
  }

  render() {
    return (
      <div className="home">
        <SideBar />
        <Row className="main">
          {!this.state.error ? (
            <div className="container-table">
              <Table type="Log" childRef={this.tableRef} />
              <div id="contPagi" className="container-pagination">
                <div className="dataTables_wrapper table_footer">
                  <div className="info_paging_container"></div>
                  <div className="btn-table-container">
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.error === 408 ? (
            <Timeout />
          ) : (
            <ServerError />
          )}
        </Row>
      </div>
    );
  }
}

export default Log;
