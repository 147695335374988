import React, { Component } from "react";
// import DetailStorageCom from "../components/DetailStorage_Com";
import ReactDOM from "react-dom";
import SideBar from "../Component/Sidebar_Com";
import Table from "../Component/Table_Com";
import GrafikData from "../Component/GrafikData_Com";
import { Form, Row, Col } from "react-bootstrap";
import { getTime, storage, formatDate } from "../Logic/handler";
import { Button, Modal } from "react-bootstrap";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { formatNumber } from "../Logic/form_handler";
const moment = require("moment-timezone");

class DetailStorage extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.tableRef = React.createRef();
    this.$ = props.dt;
    if (props.location.state) {
      this.storage_id = props.location.state.id;
      this.curr_storage = props.location.state.quantity;
      this.max_storage = props.location.state.max;
      this.storage_name = props.location.state.name;
    }
    this.state = {
      showModal: false,
      showPrintModal: false,
      modalData: {
        category: "empty"
      },
      filter_startDate: "",
      filter_endDate: ""
    };
    this.modalBody = {
      transfer: [
        ["Tanggal Buat", "created_at"],
        ["Tanggal Transfer", "date"],
        ["Storage Asal", "storage_origin.name"],
        ["Kapasitas Awal Storage Awal", "previous_storage.origin"],
        ["Storage Tujuan", "storage_destination.name"],
        ["Kapasitas Awal Storage Awal", "previous_storage.destination"],
        ["Kuantitas", "quantity"],
        ["Keterangan", "note"]
      ],
      initial: [
        ["Tanggal Buat", "date"],
        ["Kapasitas Awal Storage Awal", "quantity"]
      ],
      buy: [
        ["Tanggal Buat", "created_at"],
        ["Tanggal Pembelian", "date"],
        ["No.SO/LO", "no_invoice"],
        ["Supplier", "company_id.name"],
        ["Storage Tujuan", "storage_destination.name"],
        ["Kapasitas Awal Storage Tujuan", "previous_storage.destination"],
        ["Penggunaan BBM", "fuel_consumption"],
        ["Kuantitas", "quantity"],
        ["Keterangan", "note"]
      ],
      sell: [
        ["Tanggal Buat", "created_at"],
        ["Tanggal Penjualan", "date"],
        ["Storage Asal", "storage_origin.name"],
        ["Kapasitas Awal Storage Awal", "previous_storage.origin"],
        ["Customer", "company_id.name"],
        ["Site", "site"],
        ["Penggunaan BBM", "fuel_consumption"],
        ["Kuantitas", "quantity"],
        ["Keterangan", "note"]
      ],
      adjustment: [
        ["Tanggal Buat", "date"],
        ["Storage", "storage_origin.name"],
        ["Kapasitas Awal Storage", "previous_storage.origin"],
        ["Kapasitas", "quantity"]
      ],
      return: [
        ["Tanggal Buat", "created_at"],
        ["Tanggal Pengembalian", "date"],
        ["Storage Tujuan", "storage_destination.name"],
        ["Kapasitas Awal Storage Tujuan", "previous_storage.destination"],
        ["Penggunaan BBM", "fuel_consumption"],
        ["Kuantitas", "quantity"],
        ["Keterangan", "note"]
      ]
    };
  }

  closeModal = () => this.setState({ showModal: false });
  closePrintModal = () => this.setState({ showPrintModal: false });
  clearFilter = () => {
    this.setState({
      filter_startDate: "",
      filter_endDate: ""
    });
  };

  print = (storageId, start, end) => {
    const dataTable = this.$("#data_table").DataTable();
    var print_data = [];
    dataTable.rows({ search: "applied" }).every(function (index) {
      var row = dataTable.row(index);
      var data = row.data();
      //Created date
      let createdDate = moment.tz(data.created_at, "Asia/Jakarta");
      createdDate = new Date(createdDate);
      createdDate =
        (createdDate.getDate() < 10 ? "0" : "") +
        createdDate.getDate() +
        "-" +
        (createdDate.getMonth() + 1 < 10 ? "0" : "") +
        (createdDate.getMonth() + 1) +
        "-" +
        createdDate.getFullYear();

      //Send date
      let sendDate = moment.tz(data.date, "Asia/Jakarta");
      sendDate = new Date(sendDate);
      sendDate =
        (sendDate.getDate() < 10 ? "0" : "") +
        sendDate.getDate() +
        "-" +
        (sendDate.getMonth() + 1 < 10 ? "0" : "") +
        (sendDate.getMonth() + 1) +
        "-" +
        sendDate.getFullYear();
      //Stock
      let stock;
      let kuantitas;
      let keterangan;
      let notes = data.note ? data.note : " - ";
      switch (data.category) {
        case "buy":
          kuantitas = "+" + formatNumber(data.quantity) + "L";
          stock =
            formatNumber(
              parseInt(data.previous_storage.destination) +
              parseInt(data.quantity)
            ) + "L";
          keterangan =
            "Dari " +
            data.company_id.name +
            " = " +
            formatNumber(data.quantity) +
            "L : " +
            notes;
          break;
        case "sell":
          kuantitas = "-" + formatNumber(data.quantity) + "L";
          stock =
            formatNumber(
              parseInt(data.previous_storage.origin) - parseInt(data.quantity)
            ) + "L";
          keterangan =
            "Ke " +
            data.company_id.name +
            " = " +
            formatNumber(data.quantity) +
            "L : " +
            notes;
          break;
        case "transfer":
          //console.log("test print: " + this.storage_id +" : " + data.storage_origin._id)
          if (storageId === data.storage_origin._id) {
            kuantitas = "-" + formatNumber(data.quantity) + "L";
            stock =
              formatNumber(
                parseInt(data.previous_storage.origin) - parseInt(data.quantity)
              ) + "L";
            keterangan =
              "Ke " +
              data.storage_destination.name +
              " = " +
              formatNumber(data.quantity) +
              "L : " +
              notes;
          } else {
            kuantitas = "+" + formatNumber(data.quantity) + "L";
            stock =
              formatNumber(
                parseInt(data.previous_storage.destination) +
                parseInt(data.quantity)
              ) + "L";
            keterangan =
              "Dari " +
              data.storage_origin.name +
              " = " +
              formatNumber(data.quantity) +
              "L : " +
              notes;
          }
          break;
        case "adjustment":
          kuantitas = "=" + formatNumber(data.quantity) + "L";
          stock = formatNumber(data.quantity) + "L";
          keterangan =
            "Mengatur kapasitas dari " +
            formatNumber(data.previous_storage.origin) +
            " L menjadi " +
            formatNumber(data.quantity) +
            "L : " +
            notes
          break;
        case "initial":
          kuantitas = 0;
          stock = formatNumber(parseInt(data.quantity)) + "L";
          keterangan =
            "Storage baru " +
            data.storage_destination.name +
            " dengan stok awal: " +
            formatNumber(data.quantity) +
            "L ";
          break;
        case "return":
          kuantitas = "+" + formatNumber(data.quantity) + "L";
          stock =
            formatNumber(
              parseInt(data.previous_storage.destination) +
              parseInt(data.quantity)
            ) + "L";
          keterangan =
            "Menerima " +
            formatNumber(data.quantity) +
            " L dari " +
            data.company_id.name +
            " : " +
            notes
          break;
        default:
          stock = "-";
          break;
      }
      // DATA ///////////////////////////////////////////////////
      let filterCurrDate = new Date(data.date);
      filterCurrDate.setHours(0, 0, 0, 0);
      var isValid = true;
      if (start) {
        let filterStartDate = new Date(start);
        filterStartDate.setHours(0, 0, 0, 0);
        console.log(
          "test startdate: " +
          filterCurrDate.getTime() +
          " : " +
          filterStartDate.getTime()
        );
        if (filterCurrDate.getTime() < filterStartDate.getTime()) {
          isValid = false;
        }
      }
      if (end) {
        let filterEndDate = new Date(end);
        filterEndDate.setHours(0, 0, 0, 0);
        console.log(
          "test enddate: " +
          filterCurrDate.getTime() +
          " : " +
          filterEndDate.getTime()
        );
        if (filterCurrDate.getTime() > filterEndDate.getTime()) {
          isValid = false;
        }
      }
      if (isValid) {
        print_data.push([
          createdDate,
          sendDate,
          _.capitalize(data.category),
          kuantitas,
          stock,
          keterangan
        ]);
      }
    });
    // print_data = this.sortData(print_data);

    this.constructPDF(print_data, start, end);
  };

  // sortData = (data) => {
  //   var tmp =[];
  //   data.map(function(a){
  //     var sd = a[1].split("-");
  //     sd = sd[1] + "-" + sd[0] + "-" + sd[2]
  //     console.log(sd);
  //     var tmpObj = {
  //       "cd":a[0],
  //       "sd":sd,
  //       "c":a[2],
  //       "k":a[3],
  //       "s":a[4],
  //       "ke":a[5]
  //     }
  //     tmp.push(tmpObj)
  //   })
  //   // var date = moment(tmp[1].sd).format("DD/MM/YYYY")
  //   // //var date = new Date(tmp[1].sd)
  //   // console.log(date)
  //   const sortData = tmp.sort(function(a,b){
  //     console.log(a.sd)
  //     return new Date(a.sd) - new Date(b.sd) ;
  //   })
  //   console.log(sortData);
  //   var sortfinal = [];
  //   sortData.map(function(a){
  //     var std = a.sd.split("-");
  //     std = std[1] + "-" + std[0] + "-" + std[2]
  //     sortfinal.push([
  //       a.cd,
  //       std,
  //       a.c,
  //       a.k,
  //       a.s,
  //       a.ke
  //     ])
  //   })
  //   console.log(sortfinal);
  //   return sortfinal
  // }

  constructPDF = (data, start, end) => {
    var nota = new jsPDF({
      orientation: "landscape",
      format: "a4",
      unit: "pt"
    });


    const pageWidth = nota.internal.pageSize.width; //Optional
    const pageHeight = nota.internal.pageSize.height; //Optional
    const desireTableWidth = 690; //acquired from total jspdf-autotable column width
    const margin = (pageWidth - desireTableWidth) / 2;

    let startDate = moment.tz(start, "Asia/Jakarta");
    startDate = new Date(startDate);
    startDate =
      (startDate.getDate() < 10 ? "0" : "") +
      startDate.getDate() +
      "/" +
      (startDate.getMonth() + 1 < 10 ? "0" : "") +
      (startDate.getMonth() + 1) +
      "/" +
      startDate.getFullYear();

    let endDate = moment.tz(end, "Asia/Jakarta");
    endDate = new Date(endDate);
    endDate =
      (endDate.getDate() < 10 ? "0" : "") +
      endDate.getDate() +
      "/" +
      (endDate.getMonth() + 1 < 10 ? "0" : "") +
      (endDate.getMonth() + 1) +
      "/" +
      endDate.getFullYear();

    nota.setFontSize(18);
    nota.text(
      "DAFTAR TRANSAKSI " + _.toUpper(this.props.location.state.name),
      pageWidth / 2,
      40,
      { align: "center" }
    );
    nota.setFontSize(14);
    if (start && end) {
      nota.text(startDate + " - " + endDate, pageWidth / 2, 60, {
        align: "center"
      });
    }
    //nota.text("DAFTAR TRANSAKSI "+"",)
    nota.setFontSize(12);
    nota.autoTable({
      head: [
        [
          "TANGGAL BUAT",
          "TANGGAL KIRIM",
          "KATEGORI",
          "KAPASITAS",
          "STOK",
          "KETERANGAN"
        ]
      ],
      body: data,
      startY: 90,
      theme: "grid",
      margin: { left: margin, right: margin },
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 1,
        fontSize: 8,
        minCellHeight: 20
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
        minCellHeight: 0
      },
      bodyStyles: {
        halign: "center",
        cellWidth: "wrap"
      },
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 80 },
        2: { cellWidth: 80 },
        3: { cellWidth: 80 },
        4: { cellWidth: 80 },
        5: { cellWidth: 300, halign: "left" } //total of 690 pt
      }
    });

    //page numbering for js pdf, thanks https://github.com/MrRio/jsPDF/issues/109
    nota.setFontSize(10); //Optional
    const pages = nota.internal.getNumberOfPages();
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 15; //Can be fixed number
      nota.setPage(j);
      //nota.text(`${j} of ${pages}`, horizontalPos, verticalPos, {align: 'center'  //Optional text styling});
      nota.text(`Halaman ${j} / ${pages}`, horizontalPos, verticalPos, {
        align: "center"
      });
    }
    nota.setPage(pages);
    nota.autoPrint();
    window.open(nota.output("bloburl"));
  };

  tandaTerimaBmmPDF = () => {
    var nota = new jsPDF({
      orientation: "potrait",
      format: "a6",
      unit: "pt"
    });


    const pageWidth = nota.internal.pageSize.width; //Optional
    const pageHeight = nota.internal.pageSize.height; //Optional
    const desireTableWidth = 690; //acquired from total jspdf-autotable column width
    const margin = (pageWidth - desireTableWidth) / 2;

    var x = 20;
    var y = 40;

    var tgl_kirim = moment(this.state.modalData.date).format("DD/MM/YYYY");
    var storage = this.state.modalData.storage_origin.name;
    var tujuan = this. state.modalData.company_id.name;
    var site = this.state.modalData.site;
    var quantity = this.state.modalData.quantity;
    var keterangan = this.state.modalData.note;
    var tgl_input = moment(this.state.modalData.created_at).format("DD/MM/YYYY");
    var jam_input = moment(this.state.modalData.created_at).format("HH:mm:ss");;
    console.log(tgl_kirim, storage, tujuan, site, quantity, keterangan, tgl_input, jam_input)


    nota.setFontSize(11);
    nota.setFont("helvetica", "bold");
    nota.text(
      "TANDA TERIMA BBM ",
      x,
      y,
      { align: "left" }
    );
    nota.setLineWidth(0.5);
    nota.line(x, y + 2, x + 114, y + 2)
    nota.setFont("helvetica", "normal");
    nota.text(
      "PT. MIGAS KUKAR MANDIRI",
      x,
      y + 13,
      { align: "left" }
    );

    var xform = x;
    var yform = y + 50;

    nota.setFont("helvetica", "normal");
    nota.text(
      "Tanggal Terima BBM",
      xform,
      yform,
      { align: "left" }
    );
    nota.text(
      ":",
      xform + 110,
      yform,
    );
    //////////////////////////////////////////
    nota.setFont("helvetica", "bold");
    nota.text(
      tgl_kirim,
      xform + 118,
      yform,
      { align: "left" }
    );
    nota.setFont("helvetica", "normal");
    //////////////////////////////////////////
  
    nota.text(
      "Dari Tangki / Storage",
      xform,
      yform += 18,
      { align: "left" }
    );
    nota.text(
      ":",
      xform + 110,
      yform,
    );
    //////////////////////////////////////////
    nota.text(
      storage,
      xform + 118,
      yform,
      { align: "left" }
    );
    //////////////////////////////////////////
    
    nota.text(
      "Tujuan",
      xform,
      yform += 18,
      { align: "left" }
    );
    nota.text(
      ":",
      xform + 110,
      yform,
    );
    //////////////////////////////////////////
    nota.setFont("helvetica", "bold");
    nota.text(
      tujuan,
      xform + 118,
      yform,
      { align: "left" }
    );
    nota.setFont("helvetica", "normal");
    //////////////////////////////////////////
    
    nota.text(
      "Site",
      xform,
      yform += 18,
      { align: "left" }
    );
    nota.text(
      ":",
      xform + 110,
      yform,
    );
    //////////////////////////////////////////
    nota.text(
      site,
      xform + 118,
      yform,
      { align: "left" }
    );
    //////////////////////////////////////////
    
    nota.text(
      "Quantity",
      xform,
      yform += 18,
      { align: "left" }
    );
    nota.text(
      ":",
      xform + 110,
      yform,
    );
    //////////////////////////////////////////
    nota.setFont("helvetica", "bold");
    nota.text(
      quantity +" Liter",
      xform + 118,
      yform,
      { align: "left" }
    );
    nota.setFont("helvetica", "normal");
    //////////////////////////////////////////

    nota.text(
      "Keterangan",
      xform,
      yform += 18,
      { align: "left" }
    );
    nota.text(
      ":",
      xform + 110,
      yform,
    );
    //////////////////////////////////////////
    nota.text(
      keterangan,
      xform + 118,
      yform,
      { align: "left" }
    );
    //////////////////////////////////////////

    nota.text(
      "Tanggal Input system",
      xform,
      yform += 50,
      { align: "left" }
    );
    nota.text(
      ":",
      xform + 110,
      yform,
    );
    //////////////////////////////////////////
    nota.text(
      tgl_input,
      xform + 118,
      yform,
      { align: "left" }
    );
    //////////////////////////////////////////

    nota.text(
      "Jam",
      xform,
      yform += 18,
      { align: "left" }
    );
    nota.text(
      ":",
      xform + 110,
      yform,
    );
    //////////////////////////////////////////
    nota.text(
      jam_input,
      xform + 118,
      yform,
      { align: "left" }
    );
    //////////////////////////////////////////
    
    nota.text(
      "Penerima:",
      xform + 50,
      yform += 60,
      { align: "center" }
    );

    nota.text(
      "Dibuat / yg menyerahkan:",
      xform + 200,
      yform,
      { align: "center" }
    );

    nota.text(
      "(.........................)",
       xform + 50,
      yform += 60,
      { align: "center" }
    );

    nota.text(
      "(.........................)",
      xform + 200,
      yform,
      { align: "center" }
    );
    
    nota.autoPrint();
    window.open(nota.output("bloburl"));
  };

  componentDidMount() {
    this.$el = this.$(this.tableRef.current);
    var componentInstance = this;
    let dataTable = this.$el.DataTable({
      serverSide: false,
      scrollY: 337,
      scrollCollapse: true,
      processing: true,
      order: [0, "asc"],
      ajax: async (data, callback, settings) => {
        let resp = await storage(
          localStorage.getItem("token"),
          null,
          "get",
          "history/" + this.storage_id
        );
        let dataInject = [];
        if (resp[0]) {
          if (resp[0].status === 200) dataInject = resp[0].data;
          callback({
            data: dataInject
          });
        } else {
          if (resp[1].status === 401) {
            this.props.history.push({
              pathname: "/",
              state: {
                code: -1,
                msg: "Token tidak valid, mohon melakukan log-in kembali."
              }
            });
            return;
          }
          this.setState({
            error: resp[1].status
          });
          return;
        }
      },
      columns: [
        {
          title: "Tgl. Buat",
          data: "created_at",
          render: (data, type, row, meta) => {
            return type === "sort" ? data : moment(data).format("L");
            // let date = moment.tz(data, "Asia/Jakarta");
            // return moment(date).format("HH:mm - DD/MM/YYYY");
            // date = new Date(date);

            // return (
            //   (date.getDate() < 10 ? "0" : "") +
            //   date.getDate() +
            //   "-" +
            //   (date.getMonth() + 1 < 10 ? "0" : "") +
            //   (date.getMonth() + 1) +
            //   "-" +
            //   date.getFullYear()
            // );
          }
        },
        {
          title: "Tgl. Kirim",
          data: "date",
          orderable: false,
          render: (data, type, row, meta) => {
            return type === "sort" ? data : moment(data).format("L");
            // return type === "sort" ? data : moment(data).format("L");
            // let date = moment.tz(data, "Asia/Jakarta");
            // return moment(date).format("HH:mm - DD/MM/YYYY");
            // date = new Date(date);

            // return (
            //   (date.getDate() < 10 ? "0" : "") +
            //   date.getDate() +
            //   "-" +
            //   (date.getMonth() + 1 < 10 ? "0" : "") +
            //   (date.getMonth() + 1) +
            //   "-" +
            //   date.getFullYear()
            // );
          }
        },
        {
          title: "Kategori",
          data: "category",
          render: (data, type, row, meta) => {
            return _.capitalize(data);
          },
          orderable: false
        },
        {
          title: "Kapasitas",
          data: "quantity",
          // render: (data, type, row, meta) => {
          //   return "Empty";
          // },
          orderable: false
        },
        {
          title: "Stok",
          data: "category",
          render: (data, type, row, meta) => {
            switch (data) {
              case "buy":
                return (
                  formatNumber(
                    parseInt(row.previous_storage.destination) +
                    parseInt(row.quantity)
                  ) + "L"
                );
                break;
              case "sell":
                return (
                  formatNumber(
                    parseInt(row.previous_storage.origin) -
                    parseInt(row.quantity)
                  ) + "L"
                );
                break;
              case "initial":
                return formatNumber(parseInt(row.quantity)) + "L";
                break;
              case "transfer":
                //return JSON.stringify(row);
                if (this.storage_id === row.storage_origin._id) {
                  return (
                    formatNumber(
                      parseInt(row.previous_storage.origin) -
                      parseInt(row.quantity)
                    ) + "L"
                  );
                } else {
                  return (
                    formatNumber(
                      parseInt(row.previous_storage.destination) +
                      parseInt(row.quantity)
                    ) + "L"
                  );
                }
                break;
              case "adjustment":
                return formatNumber(row.quantity) + "L";
                break;
              case "return":
                //return JSON.stringify(row);
                return (
                  formatNumber(
                    parseInt(row.previous_storage.destination) +
                    parseInt(row.quantity)
                  ) + "L"
                );
                break;
              default:
                return "-";
                break;
            }
          },
          orderable: false
        },
        {
          title: "Keterangan",
          data: "category",
          render: (data, type, row, meta) => {
            var notes = row.note ? row.note : " - ";
            switch (data) {
              case "buy":
                return (
                  "Dari " +
                  row.company_id.name +
                  " = " +
                  formatNumber(row.quantity) +
                  "L : " +
                  notes
                );
                break;
              case "sell":
                return (
                  "Ke " +
                  row.company_id.name +
                  " = " +
                  formatNumber(row.quantity) +
                  "L : " +
                  notes
                );
                break;
              case "transfer":
                if (this.storage_id === row.storage_origin._id) {
                  return (
                    "Ke " +
                    row.storage_destination.name +
                    " = " +
                    formatNumber(row.quantity) +
                    "L : " +
                    notes
                  );
                } else {
                  return (
                    "Dari " +
                    row.storage_origin.name +
                    " = " +
                    formatNumber(row.quantity) +
                    "L : " +
                    notes
                  );
                }
                break;
              case "adjustment":
                return (
                  "Mengatur kapasitas dari " +
                  formatNumber(row.previous_storage.origin) +
                  "L menjadi " +
                  formatNumber(row.quantity) +
                  "L : " +
                  notes
                );
                break;
              case "return":
                return (
                  "Menerima " +
                  formatNumber(row.quantity) +
                  " L dari " +
                  row.company_id.name +
                  " : " +
                  notes
                );
                break;
              case "initial":
                return (
                  "Storage baru " +
                  row.storage_destination.name +
                  " dgn stok awal: " +
                  formatNumber(row.quantity) +
                  "L "
                );
                break;
              default:
                return "-";
                break;
            }
            return JSON.stringify(row);
          },
          orderable: false
        },
        // {
        //   title: "Tujuan Storage",
        //   data: "storage_destination",
        //   render: (data, type, row, meta) => {
        //     if (data === null) return "-";
        //     else return data.name;
        //   }
        // },
        // {
        //   title: "Perusahaan",
        //   data: "company_id",
        //   render: (data, type, row, meta) => {
        //     if (data === null) return "-";
        //     else return data.name;
        //   }
        // },
        {
          title: "Detail",
          data: null,
          orderable: false
        }
      ],
      order: [[0, "asc"]],
      drawCallback: function () {
        const promise = new Promise((resolve, reject) => {
          //handle if user switch page too fast
          try {
            var api = this.api();
            var rowCount = api.rows({ page: "current" }).count();
            let td = "<td>&nbsp;</td>";
            for (let i = 0; i < api.columns()[0].length - 1; i++) {
              td = td + "<td></td>";
            }
            let rowType = rowCount % 2 === 1 ? "even" : "odd";
            for (
              var i = 0;
              i < api.page.len() - (rowCount === 0 ? 1 : rowCount);
              i++
            ) {
              componentInstance
                .$("#data_table tbody")
                .append(
                  componentInstance.$(
                    "<tr class='" + rowType + "' role='row'>" + td + "</tr>"
                  )
                );
              rowType = rowType === "odd" ? "even" : "odd";
            }
            resolve(true);
          } catch (err) {
            reject(err);
          }
        });
        promise.then(
          //no need to do anything
          resolve => { },
          reject => { }
        );
      },
      columnDefs: [
        {
          targets: 6,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showModal: true,
                    modalData: rowData
                  });
                }}
              >
                Click
              </Button>,
              td
            );
          }
        },
        {
          targets: 3,
          createdCell: (td, cellData, rowData, row, col) => {
            let text,
              color = null;
            let conditional_style = {};
            cellData = formatNumber(cellData);
            switch (rowData.category) {
              case "buy":
                text = "+" + cellData + "L";
                color = "#00c20d";
                break;
              case "return":
                text = "+" + cellData + "L";
                color = "#00c20d";
                break;
              case "sell":
                text = "-" + cellData + "L";
                color = "#ed2431";
                break;
              case "adjustment":
                text = "=" + cellData + "L";
                color = "#45b6fe";
                break;
              case "initial":
                text = "0";
                color = "#45b6fe";
                break;
              case "transfer":
                if (this.storage_id === rowData.storage_origin._id) {
                  text = "-" + cellData + "L";
                  color = "#ed2431";
                } else {
                  text = "+" + cellData + "L";
                  color = "#00c20d";
                }
                break;
              default:
                //return
                text = cellData;
                color = "#000000";
                break;
            }
            conditional_style = {
              color: color,
              //textAlign: "center"
              verticalAlign: "middle",
              fontWeight: "bold"
            };
            ReactDOM.render(<p style={conditional_style}>{text}</p>, td);
          }
        }
      ]
    });
    componentInstance.$("#data_table_info").appendTo(".info_paging_container");
    componentInstance
      .$("#data_table_paginate")
      .appendTo(".info_paging_container");
    dataTable.draw();
  }

  render() {
    if (!this.props.location.state) {
      return <Redirect to={"/BadRequest"} />;
    }
    return (
      <div className="home">
        <SideBar />
        <div className="main">
          <Row className="container-row">
            <GrafikData
              quantity={this.curr_storage}
              max_capacity={this.max_storage}
              name={this.storage_name}
            />
          </Row>
          <Row className="container-row">
            <div className="container-table">
              <Table type="Detail Storage" childRef={this.tableRef} />
              <div id="contPagi" className="container-pagination">
                <div className="dataTables_wrapper table_footer">
                  <div className="info_paging_container"></div>
                  <div className="btn-table-container">
                    <div id="btn-container">
                      <Button
                        onClick={() => this.setState({ showPrintModal: true })}
                      >
                        Print
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
        <Modal show={this.state.showPrintModal} onHide={this.closePrintModal}>
          <Modal.Header>
            <Modal.Title>Print Transaksi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Tanggal Awal :</Form.Label>
                  <Form.Control
                    type="date"
                    name="start_date"
                    id="start_date"
                    value={this.state.filter_startDate}
                    max={
                      this.state.filter_endDate !== null
                        ? this.state.filter_endDate
                        : false
                    }
                    onChange={e =>
                      this.setState({ filter_startDate: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Tanggal Akhir :</Form.Label>
                  <Form.Control
                    type="date"
                    name="end_date"
                    id="end_date"
                    value={this.state.filter_endDate}
                    min={
                      this.state.filter_startDate !== null
                        ? this.state.filter_startDate
                        : false
                    }
                    onChange={e =>
                      this.setState({ filter_endDate: e.target.value })
                    }
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.print(
                  this.storage_id,
                  this.state.filter_startDate,
                  this.state.filter_endDate
                )
              }
            >
              Print
            </Button>
            <Button variant="secondary" onClick={() => this.clearFilter()}>
              Clear
            </Button>
            <Button variant="secondary" onClick={this.closePrintModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Detail Transaksi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {this.state.showModal &&
                this.modalBody[this.state.modalData.category].map(
                  (item, index) => {
                    console.log(item);
                    return (
                      <li>
                        {item[0] +
                          ": " +
                          (item[0].includes("Tanggal")
                            ? formatDate(_.get(this.state.modalData, item[1]))
                            : _.get(this.state.modalData, item[1], "-"))}
                      </li>
                    );
                  }
                )}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
            {this.state.modalData.category === "sell" && (
              <Button
                variant="secondary"
                onClick={() => {
                  console.log(this.state.modalData)
                  this.tandaTerimaBmmPDF()
                }
                }
              >
                Print
              </Button>
            )}
            {this.state.modalData.category === "sell" && (
              <Button
                variant="primary"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/Return",
                    state: {
                      transaction_id: this.state.modalData._id,
                      company_id: this.state.modalData.company_id._id,
                      storage_origin: this.state.modalData.storage_origin._id
                    }
                  })
                }
              >
                Return
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DetailStorage;