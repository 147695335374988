import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Row,
  Button,
  Modal,
  Spinner,
  Col,
  Form,
  InputGroup
} from "react-bootstrap";
// import TableStorage from "../components/TableStorage_Co
import { Link } from "react-router-dom";
import SideBar from "../Component/Sidebar_Com";
import Table from "../Component/Table_Com";
import { storage } from "../Logic/handler";
import "../Style/Table_Style.css";
import TimeOut from "./TimeOut";
import ServerError from "./ServerError";
import { formatNumber } from "../Logic/form_handler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class Storage extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.$ = props.dt;
    this.state = {
      error: null,
      data: {},
      modal: false,
      delLoading: false,
      delSuccess: null,
      gmcode: "prima3232",
      codeField: null,
      hidden: false
    };
  }

  toggleShowPassword() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    this.$el = this.$(this.tableRef.current);
    var componentInstance = this;
    let dataTable = this.$el.DataTable({
      serverSide: false,
      scrollY: 337,
      scrollCollapse: true,
      processing: true,
      ajax: async (data, callback, settings) => {
        let resp = await storage(localStorage.getItem("token"), null, "get");
        let dataInject = [];
        if (resp[0]) {
          if (resp[0].status === 200) dataInject = resp[0].data;
          callback({
            data: dataInject
          });
        } else {
          if (resp[1].status === 401) {
            this.props.history.push({
              pathname: "/",
              state: {
                code: -1,
                msg: "Token tidak valid, mohon melakukan log-in kembali."
              }
            });
            return;
          }
          this.setState({
            error: resp[1].status
          });
          return;
        }
      },
      columns: [
        {
          data: "name",
          title: "Nama Storage"
        },
        {
          data: "max_capacity",
          title: "Maksimal Storage",
          render: (data, type, row, meta) => {
            return formatNumber(data) + " L";
          }
        },
        {
          data: "quantity",
          title: "Isi Storage Sekarang",
          render: (data, type, row, meta) => {
            return formatNumber(data) + " L";
          }
        },
        {
          title: "Detail",
          data: null,
          // render: (data, type, row, meta) => {
          //  return ("<Button><a href='/DetailStorage'>Lihat</a></Button>");
          // },
          orderable: false
        },
        // {
        //   title: "Edit",
        //   data: "empty",
        //   render: (data, type, row, meta) => {
        //     return (
        //       '<div class="icon_container"><img class="icon edit" key="' +
        //       data +
        //       '" src="assets/edit.svg"/></div>'
        //     );
        //   },
        //   orderable: false,
        // },
        {
          title: "Hapus",
          data: "empty",
          render: (data, type, row, meta) => {
            return (
              '<div class="icon_container"><img class="icon delete" key="' +
              data +
              '" src="assets/trash.svg"/></div>'
            );
          },
          orderable: false
        }
      ],
      drawCallback: function() {
        const promise = new Promise((resolve, reject) => {
          //handle if user switch page too fast
          try {
            var api = this.api();
            var rowCount = api.rows({ page: "current" }).count();
            let td = "<td>&nbsp;</td>";
            for (let i = 0; i < api.columns()[0].length - 1; i++) {
              td = td + "<td></td>";
            }
            let rowType = rowCount % 2 === 1 ? "even" : "odd";
            for (
              var i = 0;
              i < api.page.len() - (rowCount === 0 ? 1 : rowCount);
              i++
            ) {
              componentInstance
                .$("#data_table tbody")
                .append(
                  componentInstance.$(
                    "<tr class='" + rowType + "' role='row'>" + td + "</tr>"
                  )
                );
              rowType = rowType === "odd" ? "even" : "odd";
            }
            resolve(true);
          } catch (err) {
            reject(err);
          }
        });
        promise.then(
          //no need to do anything
          resolve => {},
          reject => {}
        );
      },
      columnDefs: [
        {
          targets: 3,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <Button
                variant="seconday"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/DetailStorage",
                    state: {
                      id: rowData._id,
                      quantity: rowData.quantity,
                      max: rowData.max_capacity,
                      name: rowData.name
                    }
                  });
                }}
              >
                Lihat
              </Button>,
              td
            );
          }
        }
      ]
    });
    componentInstance.$("#data_table_info").appendTo(".info_paging_container");
    componentInstance
      .$("#data_table_paginate")
      .appendTo(".info_paging_container");
    dataTable.draw();
    dataTable.on("click", "img.edit", function() {
      let rowData = dataTable
        .row(componentInstance.$(this).parents("tr"))
        .data();
      console.log(rowData);
      componentInstance.props.history.push({
        pathname: "/FormStorage",
        state: rowData
      });
    });
    dataTable.on("click", "img.delete", function() {
      let rowData = dataTable
        .row(componentInstance.$(this).parents("tr"))
        .data();
      componentInstance.preview(rowData, "delete");
    });
  }

  preview = (data, type) =>
    this.setState({ modal: true, data: data, type: type });

  prevModal = () =>
    this.setState({ modal: !this.state.modal }, () =>
      this.setState({ delSuccess: null })
    );

  delete = async () => {
    this.setState({
      delLoading: true
    });
    let success = false;
    let resp = await storage(
      localStorage.getItem("token"),
      this.state.data,
      "delete"
    );
    if (resp[0]) {
      if (resp[0].status === 200) {
        success = true;
      } else {
      }
    } else {
    }
    this.setState({
      delLoading: false,
      delSuccess: success
    });
  };

  render() {
    return (
      <div className="home">
        <Modal
          backdrop={this.state.delLoading ? "static" : true}
          show={this.state.modal}
          onHide={
            this.state.delSuccess
              ? () => window.location.reload()
              : this.prevModal
          }
        >
          <Modal.Header closeButton={!this.state.delLoading}>
            Konfirmasi
          </Modal.Header>
          <Modal.Body>
            {this.state.delSuccess === null ? (
              <div>
                <p>
                  Apakah anda yakin untuk menghapus data Storage berikut ini:
                </p>
                <ul>
                  <li>Nama Storage: {this.state.data.name}</li>
                  <li>
                    Maksimal Storage :{" "}
                    {formatNumber(this.state.data.max_capacity)}
                  </li>
                  <li>
                    Isi Storage Sekarang :{" "}
                    {formatNumber(this.state.data.quantity)}
                  </li>
                </ul>
                <br />
                <p>Untuk menghapus silahkan masukan Kode GrandMaster:</p>
                <Form>
                  <Row className="justify-content-md-center">
                    <Col md="6">
                      <Form.Group>
                        <InputGroup>
                          <Form.Control
                            className="input-Password"
                            type={this.state.hidden ? "text" : "password"}
                            onChange={code =>
                              this.setState({
                                codeField: code.target.value
                              })
                            }
                            disabled={this.state.loading}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              {!this.state.hidden ? (
                                <FontAwesomeIcon
                                  onClick={() => {
                                    this.toggleShowPassword();
                                  }}
                                  icon={faEyeSlash}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  onClick={() => {
                                    this.toggleShowPassword();
                                  }}
                                  icon={faEye}
                                />
                              )}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        {/* <Form.Control 
                          type="text"
                          placeholder="Kode GrandMaster"
                          onChange={(code) => this.setState({
                            codeField: code.target.value
                          })}
                        /> */}
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </div>
            ) : this.state.delSuccess ? (
              <p>
                Data Storage dengan Nama <b>{this.state.data.name}</b> telah
                berhasil dihapus
              </p>
            ) : (
              <>
                <p>
                  Perhatian: terjadi kesalahan ketika sedang mencoba menghapus
                  data aset.
                </p>
                <p>
                  Silahkan coba beberapa saat lagi, atau hubungi admin bila
                  masalah masih berlangsung.
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {
              <>
                {this.state.delSuccess === null && (
                  <Button
                    onClick={this.prevModal}
                    disabled={this.state.delLoading}
                  >
                    Batalkan
                  </Button>
                )}
                <Button
                  onClick={
                    this.state.delSuccess === null
                      ? this.delete
                      : this.state.delSuccess
                      ? () => window.location.reload()
                      : this.prevModal
                  }
                  variant="secondary"
                  disabled={
                    this.state.delLoading ||
                    this.state.codeField != this.state.gmcode
                  }
                >
                  {this.state.delSuccess === null ? (
                    this.state.delLoading ? (
                      <Spinner variant="secondary" animation="border" />
                    ) : (
                      "Ya"
                    )
                  ) : (
                    "Tutup"
                  )}
                </Button>
              </>
            }
          </Modal.Footer>
        </Modal>
        <SideBar />
        <Row className="main">
          {!this.state.error ? (
            <div className="container-table">
              <Table type="Storage" childRef={this.tableRef} />
              <div id="contPagi" className="container-pagination">
                <div className="dataTables_wrapper table_footer">
                  <div className="info_paging_container"></div>
                  <div className="btn-table-container">
                    <div id="btn-container">
                      <Link to="/FormStorage">
                        <Button>Tambah</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.error === 408 ? (
            <TimeOut />
          ) : (
            <ServerError />
          )}
        </Row>
      </div>
    );
  }
}

export default Storage;