import React, { Component } from "react";
import SideBar from "../Component/Sidebar_Com"; 
import GantiPasswordCom from "../Component/GantiPassword_Com";
import { Toast, Row } from "react-bootstrap";
import { getTime } from "../Logic/handler";

class GantiPassword extends Component {
    constructor(props) {
        super(props);
        this.state={
            showToast: [],
            toast_data: []
        }
    }

    close(index,pos){//destroy
        let show_arr = [...this.state.showToast];
        show_arr[index] = false;
        this.setState({
            showToast: show_arr
        },this.destroy(pos));
    }

    destroy(position){//nge rerender ketika masih ada yg di show apakah ga bakal masalah?
        let toast_data = [...this.state.toast_data];
        toast_data.splice(position,1,null);
        this.setState({
            toast_data : toast_data
        })
    }

    move = () => {
        this.props.history.push("/TableProduk");
    }
    generateToast = (status,message) => {
        let idx = this.state.showToast.length
        let header = "";
        let header_class = ""
        switch(status){
            case 0: //success
                header_class="success"
                header="Success!!"
                break;
            case 1: //error from input
                header_class="warning"
                header="Error!!"
                break;
            case 2: //error from server
                header_class="danger"
                header="Error!!"
                break;
            default:
                header_class=""
                header="Unknown"
                break;
        }
        let data = {
            class: header_class,
            header: header,
            msg: message,
            idx: idx,
            pos: this.state.toast_data.length
        }
        this.setState({
            toast_data: this.state.toast_data.concat(data),
            showToast: this.state.showToast.concat(true)
        })
    }

    render() {
        return (
            <Row className="main gantiPassword" >
                <SideBar/>
                <div className="toast_container">
                {
                    this.state.toast_data.map((item) => {
                        return item !== null && (
                        <Toast animation className={item.class || "unknown"} show={this.state.showToast[item.idx]} onClose={() => this.close(item.idx,item.pos)} autohide delay={5000}>
                            <Toast.Header>
                                <strong className={"mr-auto "}>{item.header || "Unknown"}</strong>
                                <small>{getTime()}</small>
                            </Toast.Header>
                            <Toast.Body>{item.msg || "Some undocummented error occured. Please contact the administrator to look at this problem"}</Toast.Body>
                        </Toast>)
                    })
                }
                </div>
                <GantiPasswordCom generateToast={this.generateToast} {...this.props}/>
            </Row>
        );
    }
}

export default GantiPassword;