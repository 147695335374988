import React from "react";
import {
  Form,
  Button,
  Col,
  Modal,
  Spinner,
  InputGroup,
  Card,
} from "react-bootstrap";
import "../Style/Colour_Style.css";
import "../Style/Form_Style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { changePassword } from "../Logic/handler";

function GantiPasswordCom(props) {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    clearErrors,
    setError,
    getValues,
  } = useForm({
    mode: "all",
    validateCriteriaMode: "all",
  });

  const [state, setState] = useState({
    hiddenOld: false,
    hiddenNew: false,
    hiddenConfirm: false,
    loading: false,
    isSuccess: null,
  });

  const toggleShowOldPassword = () => {
    setState({
      ...state,
      hiddenOld: !state.hiddenOld,
    });
  };
  const toggleShowNewPassword = () => {
    setState({
      ...state,
      hiddenNew: !state.hiddenNew,
    });
  };
  const toggleShowConfirmPassword = () => {
    setState({
      ...state,
      hiddenConfirm: !state.hiddenConfirm,
    });
  };

  const matchPass = (value) => {
    clearErrors("missmatch");
    if (getValues("newpassword") === value) {
      clearErrors("confirm_pass");
      return true;
    } else {
      setError("missmatch", {
        type: "notMatch",
        message: "Password konfirmasi tidak sesuai dengan password baru",
      });
      return false;
    }
  };

  const submit = async (value) => {
    setState({
      ...state,
      loading: true,
    });
    let success = false;
    let resp = await changePassword(localStorage.getItem("token"), value);
    if (resp[0]) {
      if (resp[0].status === 200) {
        success = true;
      }
    } else {
      if (resp[1].status === 401) {
        this.props.history.push({
          pathname: "/",
          state: {
            code: -1,
            msg: "Token tidak valid, mohon melakukan log-in kembali.",
          },
        });
        return;
      }
      props.generateToast(
        1,
        "Status " + resp[1].status + ": " + resp[1].message
      );
    }
    setState({
      ...state,
      loading: false,
      isSuccess: success,
    });
  };

  const close = () => {
    setState({ ...state, isSuccess: null });
    reset();
  };

  return (
    <>
      <div className="main-gantiPassword">
        <Card className="card-gantiPassword">
          <Card.Header>
            <h1
              style={{ fontSize: "1.5rem", textAlign: "center", padding: 20 }}
            >
              Anda Sedang Mengganti Password
            </h1>
          </Card.Header>
          <Form
            noValidate
            onSubmit={handleSubmit(submit)}
          >
            <Card.Body>
              <Form.Group
                as={Col}
                className="form-group-GantiPassword"
                controlId="formBasicEmail"
              >
                <Form.Label>Password Lama :</Form.Label>
                <InputGroup>
                  <Form.Control
                    className={errors.oldpassword && "invalid"}
                    name="oldpassword"
                    type={!state.hiddenOld ? "password" : "text"}
                    ref={register({ required: "Input tidak boleh kosong" })}
                    disabled={state.loading}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      {!state.hiddenOld ? (
                        <FontAwesomeIcon
                          onClick={toggleShowOldPassword}
                          icon={faEyeSlash}
                        />
                      ) : (
                        <FontAwesomeIcon
                          onClick={toggleShowOldPassword}
                          icon={faEye}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ErrorMessage
                  errors={errors}
                  name="oldpassword"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                className="form-group-GantiPassword"
                controlId="formBasicPassword"
              >
                <Form.Label>Password Baru :</Form.Label>
                <InputGroup>
                  <Form.Control
                    className={errors.newpassword && "invalid"}
                    name="newpassword"
                    type={!state.hiddenNew ? "password" : "text"}
                    minLength="8"
                    ref={register({
                      required: "Input tidak boleh kosong",
                      minLength: {
                        value: 8,
                        message: "Password minimal 8 digit",
                      },
                      validate: {
                        same: (value) =>
                          value !== getValues("oldpassword") ||
                          "Password baru tidak boleh sama dengan password lama",
                      },
                    })}
                    disabled={state.loading}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      {!state.hiddenNew ? (
                        <FontAwesomeIcon
                          onClick={toggleShowNewPassword}
                          icon={faEyeSlash}
                        />
                      ) : (
                        <FontAwesomeIcon
                          onClick={toggleShowNewPassword}
                          icon={faEye}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ErrorMessage
                  errors={errors}
                  name="newpassword"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                className="form-group-GantiPassword"
                controlId="formBasicPassword"
              >
                <Form.Label>Konfirmasi Password :</Form.Label>
                <InputGroup>
                  <Form.Control
                    formNoValidate
                    className={errors.confirmpassword && "invalid"}
                    name="confirmpassword"
                    type={!state.hiddenConfirm ? "password" : "text"}
                    ref={register({
                      required: "Input tidak boleh kosong",
                      validate: {
                        notMatch: (value) => matchPass(value),
                      },
                    })}
                    disabled={state.loading}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      {!state.hiddenConfirm ? (
                        <FontAwesomeIcon
                          onClick={toggleShowConfirmPassword}
                          icon={faEyeSlash}
                        />
                      ) : (
                        <FontAwesomeIcon
                          onClick={toggleShowConfirmPassword}
                          icon={faEye}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ErrorMessage
                  errors={errors}
                  name="confirmpassword"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="missmatch"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <div className="btn-container gantiPassword">
                <Button
                  variant="secondary"
                  className="btn-secondary"
                  onClick={reset}
                >
                  Reset
                </Button>
                <Button
                  variant="primary"
                  id="btn-gantiPassword"
                  type="submit"
                  disabled={state.loading}
                >
                  {state.loading ? (
                    <Spinner animation="border" variant="secondary" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Card.Footer>
          </Form>
        </Card>
      </div>
      <Modal show={state.isSuccess} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Perhatian </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Password anda telah berhasil diganti.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GantiPasswordCom;
