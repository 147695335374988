import React, { useState } from "react";
import { Form, Button, Spinner, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "../Style/Form_Style.css";
import "../Style/Default_Style.css";
import { getCompleteTime, storage } from "../Logic/handler";
import { handleNumberInput } from "../Logic/form_handler";

function FormStorageCom(props) {
  const { register, errors, handleSubmit, clearErrors, setError, setValue, getValues } = useForm({
    mode: "all",
    defaultValues: {
      name: props.location.state ? props.location.state.name : "",
      quantity: props.location.state ? props.location.state.quantity : "",
      max_capacity : props.location.state ? props.location.state.max_capacity : ""
    },
  });
  const [state, setState] = useState({
    edit: props.location.state ? true : false,
    loading: false,
  });

  const validatePass = (value) => {
    clearErrors("req");
    if (value === "") {
      clearErrors("req");
      return true;
    } else if (props.location.state.quantity < props.location.state.max_capacity) {
      setError(
        "req",
        "maxLength",
        "Kapasitas tidak boleh melebih maksimal kapasitas"
      );
      return false;
    } else return true;
  };

  const onSubmit = async (data) => {
    setState({ ...state, loading: true });
    data.date = getCompleteTime();
    data._id = props.location.state ? props.location.state._id : null;
    let success = false;
    let res = await storage(
      localStorage.getItem("token"),
      data,
      state.edit ? "put" : "post"
    );
    if (res[0]) {
      if (res[0].status === 200) {
        props.showModal(true);
      }
    } else {
      if (res[1].status === 401) {
        this.props.history.push({
          pathname: "/",
          state: {
            code: -1,
            msg: "Token tidak valid, mohon melakukan log-in kembali.",
          },
        });
        return;
      }
      if (Array.isArray(res[1].data)) {
        if (res[1].data) {
          for (const key in res[1].data) {
            props.generateToast(
              1,
              "Status " + res[1].status + ": " + res[1].data[key].msg,
              props.setParent,
              props.toast
            );
          }
        }
      } else {
        props.generateToast(
          1,
          "Status " + res[1].status + ": " + res[1].message,
          props.setParent,
          props.toast
        );
      }
    }
    setState({ ...state, loading: false });
    if (success) props.showModal(state.edit ? "edit" : "input", res[0].data);
  };

  return (
    <div className="container-form form-storage">
      {state.edit && (
        <h4>
          Sedang mengedit Storage
          <b>
            {" "}
            {props.location.state.name}
          </b>
        </h4>
      )}
      <Card className="card-form">
        <Card.Header>
          <h5>Form Storage</h5>
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)} className="form">
          <Card.Body>
            <Form.Group controlId="formBasicStorage">
              <Form.Label>Nama Storage :</Form.Label>
              <Form.Control
                name="name"
                type="text"
                ref={register({
                  required: "Input tidak boleh kosong.",
                })}
                disabled = {state.edit ? true : false}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Group controlId="formBasicKuantitas">
              <Form.Label>Stok Awal (Liter) :</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  let res = handleNumberInput(e);
                  setValue("quantity", res);
                }}
                name="quantity"
                ref={register({
                  required: "Input tidak boleh kosong.",
                  maxLength: (value) => validatePass(value)
                })}
              />
              <ErrorMessage
                errors={errors}
                name="quantity"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Group controlId="formBasicMaxStorage">
              <Form.Label>Maksimal Kuantitas (Liter) :</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  let res = handleNumberInput(e);
                  setValue("max_capacity", res);
                }}
                disabled = {state.edit ? true : false}
                name="max_capacity"
                ref={register({
                  required: "Input tidak boleh kosong.",
                  maxLength: {
                    
                  }
                })}
              />
              <ErrorMessage
                errors={errors}
                name="max_capacity"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
          </Card.Body>
          <Card.Footer>
            <div className="btn-container">
              <Button variant="primary" type="submit" disabled={state.loading}>
                {state.loading ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Card.Footer>
        </Form>
      </Card>
    </div>
  );
}

export default FormStorageCom;
