import React from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ component: Component, dt:DT, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem("token")) 
          return <Redirect to={{pathanme:"/",state:{code:-1}}}/>
        else return  <Component dt={DT} {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
