import React, { useState } from "react";
import { Form, Col, Button, Spinner, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "../Style/Form_Style.css";
import "../Style/Default_Style.css";
import { handleNumberInput, formatNumber } from "../Logic/form_handler";
import { getCompleteTime, transaction } from "../Logic/handler";

function AdjustmentCom(props) {
  const [state, setState] = useState({
    isSubmitting: false,
    maxQuantity: 0,
  });
  const { register, errors, handleSubmit, setValue } = useForm({ mode: "all" });

  const onSubmit = async (data) => {
    setState({
      ...state,
      isSubmitting: true,
    });
    data.category = "adjustment";
    data.date = getCompleteTime();
    //data.quantity = parseInt(data.quantity);
    let res = await transaction(localStorage.getItem("token"), data, "post");
    if (res[0]) {
      if (res[0].status === 200) {
        props.showModal(true);
      }
    } else {
      if (res[1].status === 401) {
        this.props.history.push({
          pathname: "/",
          state: {
            code: -1,
            msg: "Token tidak valid, mohon melakukan log-in kembali.",
          },
        });
        return;
      }
      if (Array.isArray(res[1].data)) {
        if (res[1].data) {
          for (const key in res[1].data) {
            props.generateToast(
              1,
              "Status " + res[1].status + ": " + res[1].data[key].msg,
              props.setParent,
              props.toast
            );
          }
        }
      } else {
        props.generateToast(
          1,
          "Status " + res[1].status + ": " + res[1].message,
          props.setParent,
          props.toast
        );
      }
    }
    setState({
      ...state,
      isSubmitting: false,
    });
  };

  return (
    <div className="container-form form-page">
      <Card className="card-form">
        <Card.Header>
          <h5>Adjustment</h5>
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)} className="form">
          <Card.Body>
            <Form.Group controlId="formBasicAsal">
              <Form.Label>Storage :</Form.Label>
              <Form.Control
                name="storage_origin"
                as="select"
                ref={register({
                  required: "Input tidak boleh kosong.",
                })}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setValue(
                      "curr_quantity",
                      formatNumber(props.storageData[
                        e.target.selectedOptions[0].getAttribute("idx")
                      ].quantity)
                    );
                    setState({
                      ...state,
                      maxQuantity:
                      formatNumber(props.storageData[
                          e.target.selectedOptions[0].getAttribute("idx")
                        ].max_capacity),
                    });
                  } else {
                    setState({
                      ...state,
                      maxQuantity: 0,
                    });
                  }
                }}
              >
                <option value="" key={-1} idx={-1}>
                  Pilih Tujuan Transfer BBM
                </option>
                {props.storageData &&
                  props.storageData.map((item, index) => (
                    <option
                      name="asal_bbm"
                      value={item._id}
                      key={item._id}
                      idx={index}
                    >
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
              <ErrorMessage
                errors={errors}
                name="storage_origin"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicKuantitas">
                <Form.Label>Kuantitas (Liter) :</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    let res = handleNumberInput(e);
                    setValue("quantity", res);
                  }}
                  name="quantity"
                  ref={register({
                    required: "Input tidak boleh kosong.",
                    validate: (value) => {
                      let tempVal = parseInt(value);
                      if (state.maxQuantity < 1)
                        return "Anda belum memilih storage";
                      if (tempVal > state.maxQuantity)
                        return (
                          "Kapasitas melebihi kemampuan storage: " +
                          state.maxQuantity
                        );
                      else return true;
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="quantity"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicAsal">
                <Form.Label>Kuantitas Sekarang (Liter) :</Form.Label>
                <Form.Control
                  name="curr_quantity"
                  type="text"
                  disabled
                  ref={register({})}
                />
                <ErrorMessage
                  errors={errors}
                  name="curr_quantity"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
              </Form.Group>
            </Form.Row>
            <Form.Group controlId="formBasicKeterangan">
              <Form.Label>Keterangan({<em>Optional</em>}) :</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                name="note"
                ref={register()}
              />
            </Form.Group>
          </Card.Body>
          <Card.Footer>
            <div className="btn-container">
              <Button
                variant="primary"
                type="submit"
                disabled={state.isSubmitting}
              >
                {state.isSubmitting ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Card.Footer>
        </Form>
      </Card>
    </div>
  );
}

export default AdjustmentCom;
