import React, { useState } from "react";
import { Form, Col, Button, Spinner, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "../Style/Form_Style.css";
import "../Style/Default_Style.css";
import { transaction } from "../Logic/handler";
import { handleNumberInput } from "../Logic/form_handler";

function PenjualanCom(props) {
  const [state, setState] = useState({
    isSubmitting: false,
  });

  const { register, errors, handleSubmit, setValue, getValues } = useForm({
    mode: "all",
  });

  const onSubmit = async (data) => {
    setState({
      ...state,
      isSubmitting: true,
    });
    data.category = "sell";
    // data.quantity = parseInt(data.quantity);
    // data.fuel_consumption = parseInt(data.fuel_consumption);
    let res = await transaction(localStorage.getItem("token"), data, "post");
    if (res[0]) {
      if (res[0].status === 200) {
        props.showModal(true);
      }
    } else {
      if (res[1].status === 401) {
        this.props.history.push({
          pathname: "/",
          state: {
            code: -1,
            msg: "Token tidak valid, mohon melakukan log-in kembali.",
          },
        });
        return;
      }
      if (Array.isArray(res[1].data)) {
        if (res[1].data) {
          for (const key in res[1].data) {
            props.generateToast(
              1,
              "Status " + res[1].status + ": " + res[1].data[key].msg,
              props.setParent,
              props.toast
            );
          }
        }
      } else {
        props.generateToast(
          1,
          "Status " + res[1].status + ": " + res[1].message,
          props.setParent,
          props.toast
        );
      }
    }
    setState({
      ...state,
      isSubmitting: false,
    });
  };

  return (
    <div className="container-form form-page">
      <Card className="card-form">
        <Card.Header>
          <h5>Form Penjualan</h5>
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)} className="form">
          <Card.Body>
            <Form.Group controlId="formBasicTanggal">
              <Form.Label>Tanggal Penjualan :</Form.Label>
              <Form.Control
                name="date"
                type="date"
                ref={register({
                  required: "Input tidak boleh kosong.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="date"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Group controlId="formBasicAsal">
              <Form.Label>Storage :</Form.Label>
              <Form.Control
                name="storage_origin"
                as="select"
                ref={register({
                  required: "Input tidak boleh kosong.",
                })}
              >
                <option value="" key={-1}>
                  Pilih Asal Storage
                </option>
                {props.data[1] &&
                  props.data[1].map((item, key) => (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
              <ErrorMessage
                errors={errors}
                name="storage_origin"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Group controlId="formBasicAsal">
              <Form.Label>Tujuan Nama Perusahaan :</Form.Label>
              <Form.Control
                name="company_id"
                as="select"
                ref={register({
                  required: "Input tidak boleh kosong.",
                })}
                // onChange={(e) =>
                //   setValue(
                //     "dest_addrs",
                //     props.data[0][e.target.selectedOptions[0].getAttribute("idx")].address
                //   )
                // }
              >
                <option value="" key={-1} idx={-1}>
                  Pilih Tujuan Transfer BBM
                </option>
                {props.data[0] &&
                  props.data[0].map((item, index) => (
                    <option
                      name="asal_bbm"
                      value={item._id}
                      key={item._id}
                      idx={index}
                    >
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
              <ErrorMessage
                errors={errors}
                name="company_id"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Group controlId="formBasicAsal">
              <Form.Label>Site :</Form.Label>
              <Form.Control
                name="site"
                type="text"
                ref={register({ required: "Input tidak boleh kosong." })}
              />
              <ErrorMessage
                errors={errors}
                name="site"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Row>
              {/* <Form.Group as={Col} controlId="formBasicConsumption">
                <Form.Label>Penggunaan BBM (Liter):</Form.Label>
                <Form.Control
                  name="fuel_consumption"
                  type="text"
                  ref={register({
                    required: "Input tidak boleh kosong.",
                  })}
                  onChange={(e) => {
                    let res = handleNumberInput(e);
                    setValue("fuel_consumption", res);
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="fuel_consumption"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
              </Form.Group> */}
              <Form.Group as={Col} controlId="formBasicKuantitas">
                <Form.Label>Kuantitas (Liter) :</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    let res = handleNumberInput(e);
                    setValue("quantity", res);
                  }}
                  name="quantity"
                  ref={register({
                    required: "Input tidak boleh kosong.",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="quantity"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
              </Form.Group>
            </Form.Row>
            <Form.Group controlId="formBasicKeterangan">
              <Form.Label>Keterangan({<em>Optional</em>}) :</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                name="note"
                ref={register()}
              />
            </Form.Group>
          </Card.Body>
          <Card.Footer>
            <div className="btn-container">
              <Button
                variant="primary"
                type="submit"
                disabled={state.isSubmitting}
              >
                {state.isSubmitting ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Card.Footer>
        </Form>
      </Card>
    </div>
  );
}

export default PenjualanCom;
