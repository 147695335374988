import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Image } from "react-bootstrap";
import "../Style/Sidebar_Style.css";
import "../Style/Colour_Style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  faExchangeAlt,
  faTags,
  faBalanceScale,
  faClipboard,
  faSignOutAlt,
  faUnlockAlt,
  faDatabase,
  faUserTie,
  faTruckLoading,
} from "@fortawesome/free-solid-svg-icons";

class SideBar extends Component {
  onPaths = (paths) => {
    return (match, location) => {
      return paths.includes(location.pathname);
    };
  };

  
  render() {
    return (
      <>
        <div className="sidenav">
          <div 
            className="logo-container"
            rel="2"
          >
            <Image
              id="logo-sidebar"
              className="logo-mkm"
              src={
                this.props.location.pathname === "/Customer/FormVendor"
                || this.props.location.pathname === "/Supplier/FormVendor" ? 
                "../assets/logo/mkm-transparent.png" : "assets/logo/mkm-transparent.png"
              }
            />
          </div>
          <div className="content-sidebar" rel="3">
            <h5 className="title-submenu">Database</h5>
            <div className="submenu">
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/Storage",
                  "/DetailStorage",
                  "/FormStorage",
                  "/Return",
                ])}
                to="/Storage"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faDatabase} />
                Storage
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/Customer", "/Customer/FormVendor"])}
                to="/Customer"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faUserTie} />
                Customer
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/Supplier", "/Supplier/FormVendor"])}
                to="/Supplier"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faTruckLoading} />
                Supplier
              </NavLink>
            </div>
            <h5 className="title-submenu">Transaksi</h5>
            <div className="submenu">
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/Pembelian"])}
                to="/Pembelian"
              >
                <FontAwesomeIcon
                  className="icon-sidebar"
                  icon={faCartArrowDown}
                />
                Pembelian
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/Penjualan"])}
                to="/Penjualan"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faTags} />
                Penjualan
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/Transfer"])}
                to="/Transfer"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faExchangeAlt} />
                Transfer
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/Adjustment"])}
                to="/Adjustment"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faBalanceScale} />
                Adjustment
              </NavLink>
            </div>
            <NavLink
              activeClassName="active-link"
              exact
              isActive={this.onPaths(["/Log"])}
              to="/Log"
            >
              <FontAwesomeIcon className="icon-sidebar" icon={faClipboard} />
              Log
            </NavLink>
          </div>
          <div className="btn-logout-container" rel="4">
            <div className="button-bottom-container">
              <NavLink exact activeClassName="active-link" to="/GantiPassword">
                <FontAwesomeIcon className="icon-sidebar" icon={faUnlockAlt} />
                Ganti Password
              </NavLink>
              <NavLink exact to="/">
                <FontAwesomeIcon className="icon-sidebar" icon={faSignOutAlt} />
                Keluar
              </NavLink>
            </div>
            <div className="copyright-container">
              PT.Migas Kukar Mandiri&copy;{new Date().getFullYear()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SideBar);
