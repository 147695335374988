import React, {Component} from "react"
import {Image, Row, Col} from "react-bootstrap"
import '../Style/Colour_Style.css'
import '../Style/Form_Style.css'

class ServerError extends Component{
    render(){
        return(
            <div>
                <div className="unauthorize-container">
                    <Row>
                        <Col id="logo-unauthorized" sm={4}>
                            <Image src="assets/logo/mkm-transparent.png"/>
                        </Col>
                        <Col sm={7} style={{display:"flex", justifyContent:"center", alignItems:"center", marginLeft:"20px"}}>
                            <h1 style={{fontSize:"5vh"}}>500<br/>Internal Server Error</h1>
                            <h2 style={{fontSize:"3vh"}}>Silahkan hubungi administrator.</h2>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default ServerError;