import React, { useState } from "react";
import { Form, Col, Button, Spinner, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "../Style/Form_Style.css";
import "../Style/Default_Style.css";
import { handleNumberInput } from "../Logic/form_handler";
import { transaction } from "../Logic/handler";

function PembelianCom(props) {
  const { register, errors, handleSubmit, getValues, setValue } = useForm({
    mode: "all",
  });

  const [state, setState] = useState({
    isSubmitting: false,
  });

  const onSubmit = async (data) => {
    setState({
      ...state,
      isSubmitting: true,
    });
    data.category = "transfer";
    //data.quantity = parseInt(data.quantity);
    let res = await transaction(localStorage.getItem("token"), data, "post");
    if (res[0]) {
      if (res[0].status === 200) {
        props.showModal(true);
      }
    } else {
      if (res[1].status === 401) {
        this.props.history.push({
          pathname: "/",
          state: {
            code: -1,
            msg: "Token tidak valid, mohon melakukan log-in kembali.",
          },
        });
        return;
      }
      if (Array.isArray(res[1].data)) {
        if (res[1].data) {
          for (const key in res[1].data) {
            props.generateToast(
              1,
              "Status " + res[1].status + ": " + res[1].data[key].msg,
              props.setParent,
              props.toast
            );
          }
        }
      } else {
        props.generateToast(
          1,
          "Status " + res[1].status + ": " + res[1].message,
          props.setParent,
          props.toast
        );
      }
    }
    setState({
      ...state,
      isSubmitting: false,
    });
  };

  return (
    <div className="container-form form-page">
      <Card className="card-form">
        <Card.Header>
          <h5>Form Transfer</h5>
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)} className="form">
          <Card.Body>
            <Form.Group controlId="formBasicTanggal">
              <Form.Label>Tanggal Transfer :</Form.Label>
              <Form.Control
                name="date"
                type="date"
                ref={register({
                  required: "Input tidak boleh kosong.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="date"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicAsal">
                <Form.Label>Asal BBM :</Form.Label>
                <Form.Control
                  name="storage_origin"
                  as="select"
                  ref={register({
                    required: "Input tidak boleh kosong.",
                    validate: (value) =>
                      value !== getValues("storage_destination") ||
                      "Asal pengiriman tidak boleh sama dengan tujuan pengiriman",
                  })}
                >
                  <option value="" key={-1}>
                    Pilih Asal BBM
                  </option>
                  {props.storageData &&
                    props.storageData.map((item, key) => (
                      <option name="asal_bbm" value={item._id} key={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <ErrorMessage
                  errors={errors}
                  name="storage_origin"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTujuanBBM">
                <Form.Label>Tujuan Transfer BBM:</Form.Label>
                <Form.Control
                  as="select"
                  name="storage_destination"
                  ref={register({
                    required: "Input tidak boleh kosong.",
                    validate: (value) =>
                      value !== getValues("storage_origin") ||
                      "Tujuan pengiriman tidak boleh sama dengan asal pengiriman",
                  })}
                >
                  <option value="" key={-1}>
                    Pilih Tujuan Transfer BBM
                  </option>
                  {props.storageData &&
                    props.storageData.map((item, key) => (
                      <option name="asal_bbm" value={item._id} key={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <ErrorMessage
                  errors={errors}
                  name="storage_destination"
                  render={({ message }) => (
                    <p className="error_info">{message}</p>
                  )}
                />
              </Form.Group>
            </Form.Row>
            <Form.Group controlId="formBasicKuantitas">
              <Form.Label>Kuantitas (Liter) :</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  let res = handleNumberInput(e);
                  setValue("quantity", res);
                }}
                name="quantity"
                ref={register({
                  required: "Input tidak boleh kosong.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="quantity"
                render={({ message }) => (
                  <p className="error_info">{message}</p>
                )}
              />
            </Form.Group>
            <Form.Group controlId="formBasicKeterangan">
              <Form.Label>Keterangan({<em>Optional</em>}) :</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                name="note"
                ref={register()}
              />
            </Form.Group>
          </Card.Body>
          <Card.Footer>
            <div className="btn-container">
              <Button
                variant="primary"
                type="submit"
                disabled={state.isSubmitting}
              >
                {state.isSubmitting ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Card.Footer>
        </Form>
      </Card>
    </div>
  );
}

export default PembelianCom;
