import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  Row,
  Button,
  Modal,
  Spinner,
  Col,
  Form,
  InputGroup
} from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import SideBar from "../Component/Sidebar_Com";
import Table from "../Component/Table_Com";
import "../Style/Table_Style.css";
import { company } from "../Logic/handler";
import Timeout from "./TimeOut";
import ServerError from "./ServerError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Vendor(props) {
  const tableRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [modal, setModal] = useState({
    modal: false,
    data: {},
    type: null,
  });
  const [state, setState] = useState({
    data: {},
    firstInitial: true,
    error: null,
    delLoading: false,
    delSuccess: null,
  });

  const [codeAuth, setCodeAuth] = useState({
    codeField: null,
    hidden: false,
  });

  const [gmcode, setgmcode] = useState("prima3232");

  useEffect(() => {
    const process = async() => {
      const $el = props.dt(tableRef.current);
      var componentInstance = props.dt;
      let dataTable = $el.DataTable({
        serverSide: false,
        scrollY: 337,
        scrollCollapse: true,
        processing: true,
        ajax: async (data, callback, settings) => {
          let resp = await company(
            localStorage.getItem("token"),
            null,
            "get",
            location.pathname === "/Customer" ? "customer" : "supplier"
          );
          let dataInject = [];
          if (resp[0]) {
            if (resp[0].status === 200) dataInject = resp[0].data;
            callback({
              data: dataInject,
            });
          } else {
            if (resp[1].status === 401) {
              this.props.history.push({
                pathname: "/",
                state: {
                  code: -1,
                  msg: "Token tidak valid, mohon melakukan log-in kembali.",
                },
              });
              return;
            }
            setState({...state, error: resp[1].status });
            return;
          }
        },
        columns: [
          { title: "Nama Perusahaan", data: "name" },
          { title: "Alamat Perusahaan", data: "address" },
          {
          title: "Detail",
          data: null,
          orderable: false,
        },
          {
            title: "Edit",
            data: "empty",
            render: (data, type, row, meta) => {
              return (
                '<div class="icon_container"><img class="icon edit" key="' +
                data +
                '" src="assets/edit.svg"/></div>'
              );
            },
            orderable: false,
          },
          {
            title: "Hapus",
            data: "empty",
            render: (data, type, row, meta) => {
              return (
                '<div class="icon_container"><img class="icon delete" key="' +
                data +
                '" src="assets/trash.svg"/></div>'
              );
            },
            orderable: false,
          },
        ],
        drawCallback: function () {
          const promise = new Promise((resolve, reject) => {
            //handle if user switch page too fast
            try {
              var api = this.api();
              var rowCount = api.rows({ page: "current" }).count();
              let td = "<td>&nbsp;</td>";
              for (let i = 0; i < api.columns()[0].length - 1; i++) {
                td = td + "<td></td>";
              }
              let rowType = rowCount % 2 === 1 ? "even" : "odd";
              for (
                var i = 0;
                i < api.page.len() - (rowCount === 0 ? 1 : rowCount);
                i++
              ) {
                componentInstance("#data_table tbody").append(
                  componentInstance(
                    "<tr class='" + rowType + "' role='row'>" + td + "</tr>"
                  )
                );
                rowType = rowType === "odd" ? "even" : "odd";
              }
              resolve(true);
            } catch (err) {
              reject(err);
            }
          });
          promise.then(
            //no need to do anything
            (resolve) => {},
            (reject) => {}
          );
        },columnDefs: [
        {
          targets: 2,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <Button
                variant="seconday"
                onClick={() => {
                  console.log("test:" + rowData.name)
                  history.push({
                    pathname: "/TransactionCustomer",
                    state: {
                      id: rowData._id,
                      name: rowData.name
                    },
                  });
                }}
              >
                Lihat
              </Button>,
              td
            );
          },
        },
      ],
      });
      componentInstance("#data_table_info").appendTo(".info_paging_container");
      componentInstance("#data_table_paginate").appendTo(
        ".info_paging_container"
      );
      dataTable.draw();

      //Bagian Edit
      dataTable.on("click", "img.edit", function () {
        let rowData = dataTable
          .row(componentInstance(this).parents("tr"))
          .data();
        console.log(rowData);
        if (location.pathname === "/Customer") {
          console.log(location.pathname);
          history.push({
            pathname: "/Customer/FormVendor",
            state: rowData,
          });
        } else {
          console.log(location.pathname);
          // componentInstance.history.push({
          //   pathname: "/Supplier/FormVendor",
          //   state: rowData,
          // });
          history.push({
            pathname: "/Supplier/FormVendor",
            state: rowData,
          });
        }
      });

      //Bagian Delete
      dataTable.on("click", "img.delete", function () {
        let rowData = dataTable
          .row(componentInstance(this).parents("tr"))
          .data();
        preview(rowData, "delete");
      });
      props.dt.fn.dataTable.Api.register("processing()", function (show) {
        return this.iterator("table", function (ctx) {
          ctx.oApi._fnProcessingDisplay(ctx, show);
        });
      });
      setState({ ...state, error: false });
    }
    process();
  }, []);

  useEffect(() => {
    if (!state.firstInitial) {
      const process = async() => {
        const $el = props.dt("#data_table").DataTable();
        await $el.clear().draw().processing(true);
        let resp = await company(
          localStorage.getItem("token"),
          null,
          "get",
          location.pathname === "/Customer" ? "customer" : "supplier"
        );
        console.log(resp);
        let dataInject = [];
        if (resp[0]) {
          if (resp[0].status === 200) {
            console.log(resp[0].data);
            dataInject = resp[0].data;
            let i = 1;
            for (var obj in dataInject) {
              obj = {
                ...dataInject[obj],
                no: i,
              };
              dataInject[i - 1] = obj;
              i++;
            }
          }
          $el.rows.add(dataInject).draw().processing(false);
        } else {
          setState({...state, error:resp[1].status});
          return;
        }
      }
      process();
    }
  }, [location.pathname]);

  const preview = (data, type) =>
    setModal({ ...modal, modal: true, data: data, type: type });

  useEffect(()=>{
    setState({ ...state,delSuccess: null });
  },[modal])

  const prevModal = () =>
    setModal({ ...modal, modal: !modal.modal });

  const deleteData = async () => {
    setState({...state, delLoading: true});
    let success = false;
    let resp = await company(
      localStorage.getItem("token"),
      modal.data,
      "delete"
    );
    console.log(resp)
    if (resp[0]) {
      if (resp[0].status === 200) {
        success = true;
      } else {
      }
    } else {
    }
    setState({
      ...state,
      delLoading: false,
      delSuccess: success,
    });
  };

  return (
    <div className="home">
      <Modal
        backdrop={state.delLoading ? "static" : true}
        show={modal.modal}
        onHide={state.delSuccess ? () => window.location.reload() : prevModal}
      >
        <Modal.Header closeButton={!state.delLoading}>Konfirmasi</Modal.Header>
        <Modal.Body>
          {state.delSuccess === null ? (
            <div>
              <p>
                Apakah anda yakin untuk menghapus data{" "}
                {location.pathname === "/Supplier" ? (
                  "Supplier"
                ) : (
                  location.pathname === "/Customer" && "Customer"
                )}{" "}
                berikut ini:
              </p>
              <ul>
                <li>Nama Perusahan: {modal.data.name}</li>
                <li>Alamat Perusahaan: {modal.data.address}</li>
              </ul>
              <br />
              <p>Untuk menghapus silahkan masukan Kode GrandMaster:</p>
                <Form>
                  <Row className="justify-content-md-center">
                    <Col md="6">
                      <Form.Group>
                        <InputGroup>
                          <Form.Control
                            className="input-Password"
                            type={state.hidden ? "text" : "password"}
                            onChange={code =>
                              setCodeAuth({...state,
                                codeField: code.target.value
                              })
                            }
                            disabled={state.delLoading}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              {!state.hidden ? (
                                <FontAwesomeIcon
                                  onClick={() => {
                                    setCodeAuth({...state, hidden: !state.hidden})
                                  }}
                                  icon={faEyeSlash}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  onClick={() => {
                                    setCodeAuth({...state, hidden: !state.hidden})
                                  }}
                                  icon={faEye}
                                />
                              )}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        {/* <Form.Control 
                          type="text"
                          placeholder="Kode GrandMaster"
                          onChange={(code) => this.setState({
                            codeField: code.target.value
                          })}
                        /> */}
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
            </div>
          ) : state.delSuccess ? (
            <p>
              Data{" "}
              {location.pathname === "/Supplier" ? (
                "Supplier"
              ) : (
                location.pathname === "/Customer" && "Customer"
              )}{" "}
              dengan Nama Perusahaan <b>{modal.data.name}</b>{" "}
              telah berhasil dihapus
            </p>
          ) : (
            <>
              <p>
                Perhatian: terjadi kesalahan ketika sedang mencoba menghapus
                data {location.pathname === "/Supplier" ? (
                  "Supplier"
                ) : (location.pathname === "/Customer" && "Customer")}.
              </p>
              <p>
                Silahkan coba beberapa saat lagi, atau hubungi admin bila
                masalah masih berlangsung.
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {
            <>
              {state.delSuccess === null && (
                <Button
                  onClick={prevModal}
                  disabled={state.delLoading}
                >
                  Batalkan
                </Button>
              )}
              <Button
                onClick={
                  state.delSuccess === null
                    ? deleteData
                    : state.delSuccess
                    ? () => window.location.reload()
                    : prevModal
                }
                variant="secondary"
                 disabled={state.delLoading || codeAuth.codeField != gmcode}
              >
                {state.delSuccess === null ? (
                  state.delLoading ? (
                    <Spinner variant="secondary" animation="border" />
                  ) : (
                    "Ya"
                  )
                ) : (
                  "Tutup"
                )}
              </Button>
            </>
          }
        </Modal.Footer>
      </Modal>
      <SideBar />
      <Row className="main">
        {!state.error ? (
          <div className="container-table">
            <Table type="Vendor" childRef={tableRef} />
              <div id="contPagi" className="container-pagination">
                <div className="dataTables_wrapper table_footer">
                  <div className="info_paging_container"></div>
                  <div className="btn-table-container">
                    <div id="btn-container">
                      {location.pathname === "/Customer" ? (
                        <Link to="/Customer/FormVendor">
                          <Button>Tambah</Button>
                        </Link>
                      ) : (
                        location.pathname === "/Supplier" && (
                          <Link to="/Supplier/FormVendor">
                            <Button>Tambah</Button>
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        ) : state.error === 408 ? (
          <Timeout />
        ) : (
          <ServerError />
        )}
      </Row>
    </div>
  );
}
export default Vendor;